import * as React from 'react';
import { useEffect } from 'react';
import { Checkbox, TextField } from '@mui/material';
import Container from '../../components/Container';
import LongMenu from '../../components/LongMenu/LongMenu';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Pagination } from '@mui/material';
import { format } from 'date-fns';

import {
  getAllTokensThunk,
  updateTokenByIdThunk,
} from '../../redux/tokens/thunks';
import {
  MainWrapper,
  NoTopicsWrapper,
  ImageBackground,
  AllNftTokensWrapper,
  TitleNftTokens,
  NftTokensList,
  NoTopicsTitleWrapper,
  StyledTitleNoTopics,
  AvatarWrap,
  CardWrap,
  RowWrap,
  SmallText,
  TableItemStyled,
  TableItemWrap,
  TableTitleStyled,
  TableTitleWrap,
  TitleText,
  StyledImage,
  StatusIsHotNft,
  StatusIsTrusted,
} from './styles';
// import FormNewMembers from '../../components/FormNewMembers';
import { tokensSelector } from '../../redux/tokens/selectors';
import { userSelector } from '../../redux/auth/selectors';
import { ITokensList } from '../../types/token';

const Tokens: React.FC = () => {
  const dispatch = useAppDispatch();
  const tokens = useAppSelector(tokensSelector);
  const authUser: any = useAppSelector(userSelector);
  const isAdmin: boolean = Boolean(authUser?.user?.role);
  const allTokensDiv = React.useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [tokensList, setTokensList] = React.useState({});
  const [filterText, setfilterText] = React.useState('');

  const [selectedTokenId, setSelectedTokenId] = React.useState<string>('');

  useEffect(() => {
    dispatch(getAllTokensThunk({ limit: 20, page: 1, name: filterText }));
  }, [tokensList, filterText, selectedTokenId]);

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    data: ITokensList
  ) => {
    const newValueToken = {
      ...data,
      isHot: e.target.checked,
    };
    const newTokensList = await dispatch(updateTokenByIdThunk(newValueToken));
    setTokensList(newTokensList.payload);
  };

  const handleSetFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 3) {
      setfilterText(e.target.value);
    }
    if (e.target.value.length === 0) {
      setfilterText(e.target.value);
    }
  };

  const renderTokensList = (arr: ITokensList[]) => {
    return (
      <CardWrap style={{ borderCollapse: 'collapse' }}>
        <thead>
          <TableTitleWrap>
            <TableTitleStyled>{'Token`s Name'}</TableTitleStyled>
            <TableTitleStyled>{'Token`s public address'}</TableTitleStyled>
            <TableTitleStyled>{'is trusted?'}</TableTitleStyled>
            <TableTitleStyled style={{ textAlign: 'center' }}>
              {'update date'}
            </TableTitleStyled>
            <TableTitleStyled>{'is hot?'}</TableTitleStyled>
          </TableTitleWrap>
        </thead>
        <tbody>
          {arr.map((token: ITokensList) => {
            return (
              <TableItemWrap key={token._id}>
                <TableItemStyled>
                  <RowWrap>
                    <AvatarWrap>
                      <StyledImage
                        src={token.logoURI}
                        alt="logo"
                      />
                    </AvatarWrap>
                    <div>
                      <TitleText>{token.name}</TitleText>
                      <SmallText
                        style={{ marginTop: '6px', fontWeight: '700' }}
                      >
                        {token._id}
                      </SmallText>
                    </div>
                  </RowWrap>
                </TableItemStyled>
                <TableItemStyled>
                  <TitleText>{token.address}</TitleText>
                </TableItemStyled>
                <TableItemStyled style={{ textAlign: 'center' }}>
                  <StatusIsTrusted>
                    <Checkbox checked={token.isTrusted} />
                  </StatusIsTrusted>
                </TableItemStyled>
                <TableItemStyled style={{ textAlign: 'center' }}>
                  <TitleText style={{ marginBottom: '6px' }}>
                    {token.updatedAt
                      ? format(new Date(`${token.updatedAt}`), 'dd.MM.yyyy')
                      : '-'}
                  </TitleText>
                </TableItemStyled>

                <TableItemStyled>
                  <StatusIsHotNft>
                    <Checkbox
                      checked={token.isHot}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, token)
                      }
                    />
                    {/* <TableItemStyled>
                      <StyledTDataAction>
                        <LinkWrapper to={``}>
                          <Tooltip
                            title={ICON_TITLE.SHOW_DETAILS}
                            arrow
                          >
                            <ActionTopicButton
                              onClick={() => handleDelete(token._id)}
                              text={BTN_TITLE.DELETE}
                            />
                          </Tooltip>
                        </LinkWrapper>
                      </StyledTDataAction>
                    </TableItemStyled> */}
                    <LongMenu
                      userId={token._id}
                      setSelectedUserId={setSelectedTokenId}
                      setOpenModal={setOpenModal}
                      isAdmin={isAdmin}
                    />
                  </StatusIsHotNft>
                </TableItemStyled>
              </TableItemWrap>
            );
          })}
        </tbody>
      </CardWrap>
    );
  };

  return (
    <Container>
      <MainWrapper>
        <AllNftTokensWrapper ref={allTokensDiv}>
          <TitleNftTokens>All Tokens</TitleNftTokens>
          <TextField
            label="Filter By Token's Name"
            variant="filled"
            InputLabelProps={{ style: { fontWeight: 400, color: 'white' } }}
            sx={{
              color: 'white',
              width: 300,
              marginBottom: 7,
              fontWeight: 900,
              '& .Mui-focused': {
                backgroundColor: 'transparent',
                color: 'white',
              },
            }}
            onChange={handleSetFilter}
          />
          {tokens?.docs.length ? (
            <>
              <NftTokensList>{renderTokensList(tokens?.docs)}</NftTokensList>
              <Pagination
                hideNextButton
                hidePrevButton
                defaultPage={tokens?.page}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '20px',
                  'li button': {
                    fontFamily: `"Montserrat", sans-serif`,
                    fontWeight: 600,
                    color: '#717384',
                  },
                }}
                page={tokens?.page}
                count={tokens?.totalPages}
                onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                  dispatch(
                    getAllTokensThunk({
                      limit: tokens?.limit,
                      page: page,
                    })
                  );
                }}
              />
            </>
          ) : (
            <NoTopicsWrapper>
              <ImageBackground></ImageBackground>
              <NoTopicsTitleWrapper>
                <StyledTitleNoTopics>Have no tokens</StyledTitleNoTopics>
              </NoTopicsTitleWrapper>
            </NoTopicsWrapper>
          )}
        </AllNftTokensWrapper>
      </MainWrapper>
    </Container>
  );
};

export default Tokens;
