import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config/api';
import { API_ROUTES } from '../../config/apiRoutes';
import { IPaginate } from '../../types/pagination';
import {
  IProjectsListQueryData,
  IProjects,
  GetProjectQuery,
} from '../../types/project';

/**
 * @description Dispatch this thunk to get list of users
 * @param {IUsersListQueryData} queryData - Object containing limit and page (used for pagination)
 */
export const getAllProjectsThunk = createAsyncThunk<
  IPaginate<IProjects>,
  GetProjectQuery
>(
  'projects/getAllProjectsThunk',
  async (
    queryData: IProjectsListQueryData | undefined,
    { rejectWithValue }
  ) => {
    const { limit = 30, page = 1, name } = queryData ?? {};
    try {
      const { data } = await API.get(
        API_ROUTES.PROJECTS.GET_LIST(limit, page, name)
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
/**
 * @description Dispatch this thunk to get project by id
 * @param {string} id - String id to find current project
 */
export const getProjectByIdThunk = createAsyncThunk(
  'projects/getProjectByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.PROJECTS.GET_BY_ID(id));
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/**
 * @description Dispatch this thunk to update project by id
 * @param {string} id - String id to find current project
 */
export const updateProjectByIdThunk = createAsyncThunk(
  'projects/updateProjectByIdThunk',
  async (values: IProjects, { rejectWithValue }) => {
    try {
      const { data } = await API.put(API_ROUTES.PROJECTS.UPDATE(values._id), {
        ...values,
      });
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
/**
 * @description Dispatch this thunk to update project status by id
 * @param {string} id - String id to find current project
 */
export const updateProjectSTatusByIdThunk = createAsyncThunk(
  'projects/updateProjectByIdThunk',
  async (values: { _id: string; status: string }, { rejectWithValue }) => {
    try {
      const { data } = await API.patch(
        API_ROUTES.PROJECTS.CHANGE_STATUS(values._id),
        {
          ...values,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
/**
 * @description Dispatch this thunk to delete project by id
 * @param {string} id - String id to find current project
 */
export const deleteProjectByIdThunk = createAsyncThunk(
  'projects/deleteProjectByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(API_ROUTES.PROJECTS.DELETE_BY_ID(id));
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
