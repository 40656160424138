import { Tabs, Tab, styled, Box, Button, Grid } from '@mui/material';
import {
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
  Border,
} from '../../helpers/themeStyles';
interface ItemIndicatorProps {
  width?: number;
}
export const StyledProjectPageWrapper = styled('div')({
  display: 'flex',
  padding: '0 24px',
  width: '76vw',
});

export const StyledProjectDetailsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
});

export const StyledProjectFinInfoContainer = styled('div')({
  display: 'block',
  width: '26%',
  marginLeft: '20px',
});

export const StyledHeadingContainer = styled('div')({
  display: 'flex',
  columnGap: '10px',
  color: Color.WHITE,
  margin: '20px 0px',
});

export const StyledLogo = styled('img')({});
export const StyledImg = styled('img')({
  width: '100%',
});
export const StyledProjectName = styled('h1')({
  margin: 0,
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.MIDDLE_PLUS,
  textDecoration: 'underline',
});

export const StyledProjectDescription = styled('p')({
  margin: 0,
  fontWeight: 'bold',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
});

export const StyledProjectBanner = styled('div')(
  ({ bannerLink }: { bannerLink?: string }) => ({
    position: 'relative',
    backgroundImage: `url(${bannerLink})`,
    backgroundSize: 'cover',
    height: '500px',
    width: '100%',
  })
);

export const StyledProjectChain = styled('div')({
  position: 'absolute',
  top: '10px',
  right: '10px',
  display: 'flex',
  alignItems: 'center',
  columnGap: '5px',
  padding: '12px',
  backgroundColor: Color.BACKGROUND_MAIN_WITH_OPACITY,
  borderRadius: BorderRadius.NORMAL,
});

export const StyledNetworkName = styled('p')({
  margin: 0,
  color: Color.WHITE,
});

export const SocialContainer = styled('div')({
  display: 'flex',
  margin: '20px 0px',
  color: Color.WHITE,
  alignItems: 'center',
  columnGap: '50px',
});

export const SocialTitle = styled('span')({
  fontFamily: FontFamily.INTER,
  marginRight: '20px',
});

export const SocialItems = styled('div')({
  display: 'flex',
  columnGap: '10px',
});

export const SocialItem = styled('a')({
  textDecoration: 'none',
  background: Color.BACKGROUND_MAIN_WITH_OPACITY,
  border: '1px solid silver',
  borderRadius: BorderRadius.SMALL,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
});

export const StyledWebsiteLink = styled('a')({
  textDecoration: 'none',
  color: Color.LIGHT_RED,
});

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': { columnGap: '10px' },
});

export const StyledTab = styled(Tab)({
  background: Color.DARK_PURPLE_BACKGROUND,
  color: Color.WHITE,
  borderRadius: BorderRadius.NORMAL,
});

export const StyledTabButton = styled('div')({});

export const TokenSaleContainer = styled('div')({
  backgroundColor: Color.DARK_PURPLE_BACKGROUND,
  borderRadius: BorderRadius.NORMAL,
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '18px',
});

export const TokenDetailsBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
export const StyledFinancialInfoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: 150,
  width: '100%',
  padding: '16px',
  background: Color.DARK_PURPLE_OPACITY_NEXT,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: BorderRadius.SMALL,
}));

export const StyledFinancialInfoLine = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const TokenDetailsName = styled('span')({
  color: Color.WHITE,
});

export const TokenDetailssValue = styled('span')({
  color: Color.WHITE,
});

export const StyledFinancialInfoLineLabel = styled('p')({
  margin: 0,
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
});

export const StyledFinancialInfoLineValue = styled('p')({
  margin: 0,
  fontWeight: 'bold',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE,
  color: Color.WHITE,
});

export const ConnectWalletButton = styled(Button)({
  padding: '12px 24px',
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  borderRadius: BorderRadius.SEMI_SMALL,
  border: 'none',
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
  width: '100%',
});
export const ChangeStatusButton = styled(Button)({
  padding: '12px 24px',
  background: Color.BUTTON_GRADIENT_PINK_TO_PURPLE,
  borderRadius: BorderRadius.SEMI_SMALL,
  border: 'none',
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.WHITE,
  width: '100%',
  marginBottom: '10px',
});

export const StyledChapter = styled('div')({
  padding: '10px 0 5px',
  borderBottom: `1px solid ${Color.BORDER_COLOR}`,
  color: Color.WHITE,
  fontFamily: FontFamily.ZENDOTS,
  textTransform: 'uppercase',
  marginTop: '10px',
  marginRight: '10px',
});
export const StyledSimpleText = styled('p')({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE_OPACITY_LIGHT,
  fontSize: TextSize.EXTRA_SMALL,
  margin: 0,
  marginBottom: '10px',
});
export const StyledTeamContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: '20px',
  justifyContent: 'space-between',
  marginTop: '18px',
});
export const StyledTeamCard = styled('div')({
  background: Color.DARK_PURPLE_BACKGROUND,
  borderRadius: BorderRadius.NORMAL,
  padding: '18px',
  width: '40%',
});
export const StyledHeadingChapter = styled('h3')({
  margin: '18px 0px',
  fontFamily: FontFamily.ZENDOTS,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  letterSpacing: '0.05em',
  color: Color.WHITE,
});
export const StyledTeamText = styled('p')({
  marginBottom: '10px',
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.MIDDLE,
});

export const StyledLinkedInLink = styled('a')({
  textDecoration: 'none',
  color: Color.LINKEDIN_COLOR,
  fontFamily: FontFamily.INTER,
  display: 'flex',
  alignItems: 'flex-end',
  columnGap: '10px',
  fontSize: TextSize.SMALL,
  cursor: 'pointer',
  fontWeight: '700',
});
export const StyledNavWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '30px 0 0',
}));

export const StyledNavScrolledWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  position: 'fixed',
  top: 40,
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '30px 0',
}));
export const ProjectNavBarWrapper = styled('div')({
  cursor: 'pointer',
  margin: 0,
  '&:not(:last-child)': {
    marginRight: '24px',
  },
});
export const ItemIndicator = styled('div')<ItemIndicatorProps>((props) => ({
  '::after': {
    content: '""',
    width: `${props.width}px`,
    height: '2px',
    background: Color.TEXT_GRADIENT,
    position: 'absolute',
  },
}));
export const StyledNavItem = styled('p')({
  position: 'relative',
  margin: '0 0 4px 0',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.MIDDLE_PLUS,
  fontWeight: 'bold',
  color: Color.WHITE,
});
export const StyledGridSelect = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '2px 0',
}));
