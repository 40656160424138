import { useState } from 'react';
import { useFormik } from 'formik';
import { FeeProps, initialValues, Props } from '../ConnectWalletModal/config';
import { Modal, Box } from '@mui/material';
import {
  style,
  StyledButtonContainer,
  StyledFormButton,
  StyledText,
  StyledFormicError,
  StyledFormButtonNumber,
} from './styles';
import { useNetwork } from 'wagmi';
import { ContractWriteFees } from '../../helpers/contractWrite';
import { BigNumber } from 'ethers';

const ResetFeeModal = (props: FeeProps) => {
  const [feePercentageAmount, setFeePercentageAmount] = useState<BigNumber>();
  const { chain } = useNetwork();
  const { data, isLoading, isSuccess, write } = ContractWriteFees(
    chain?.id,
    BigNumber.from(feePercentageAmount ? feePercentageAmount : 0)
  );

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      write?.();

      props.handleFeeChange();
      props.handleFeeModalClose();
    },
  });

  const disabledButton = Boolean(
    formik.errors.confirmNewFee ||
      formik.errors.newFee ||
      !formik.values.confirmNewFee ||
      feePercentageAmount?.toNumber() !==
        Number(formik.values.confirmNewFee) * 100
  );

  return (
    <div>
      <Modal
        open={props.feeModal}
        onClose={props.handleFeeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledText id="modal-modal-title">Change Platform Fee</StyledText>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Box sx={{ mt: 1 }}>
              <StyledFormButtonNumber
                margin="normal"
                fullWidth
                id="currentFee"
                type="text"
                label="Current platform fee"
                name="currentFee"
                defaultValue={`${props.feeAmount} %`}
                sx={{
                  '& .MuiInputBase-input': {
                    WebkitTextFillColor: 'white',
                  },
                }}
              />

              <StyledFormButtonNumber
                color={
                  formik.errors.newFee && formik.touched.newFee
                    ? 'error'
                    : 'primary'
                }
                onChange={(event) => {
                  setFeePercentageAmount(
                    BigNumber.from(Number(event.target.value) * 100)
                  );
                }}
                margin="normal"
                required
                fullWidth
                onBlur={formik.handleBlur}
                name="newFee"
                label="New fee"
                type="number"
                id="newFee"
                autoFocus
                sx={{
                  '& .MuiInputBase-input': {
                    WebkitTextFillColor: 'white',
                  },
                }}
              />

              {formik.errors.newFee && formik.touched.newFee && (
                <StyledFormicError>{formik.errors.newFee}</StyledFormicError>
              )}
              <StyledFormButtonNumber
                onChange={formik.handleChange}
                margin="normal"
                onBlur={formik.handleBlur}
                color={
                  formik.errors.confirmNewFee && formik.touched.confirmNewFee
                    ? 'error'
                    : 'primary'
                }
                required
                fullWidth
                name="confirmNewFee"
                label="Confirm new platform fee"
                type="number"
                id="confirmNewFee"
                sx={{
                  '& .MuiInputBase-input': {
                    WebkitTextFillColor: 'white',
                  },
                }}
              />
              {formik.errors.confirmNewFee && formik.touched.confirmNewFee && (
                <StyledFormicError>
                  {formik.errors.confirmNewFee}
                </StyledFormicError>
              )}
              <StyledButtonContainer>
                <StyledFormButton
                  type="button"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={props.handleFeeModalClose}
                >
                  cancel
                </StyledFormButton>
                <StyledFormButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={disabledButton}
                >
                  change
                </StyledFormButton>
              </StyledButtonContainer>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ResetFeeModal;
