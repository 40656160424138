import { StyledButtonBlack } from './styles';

type Props = {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'reset' | 'submit';
};

const ActionTopicButton: React.FC<Props> = ({
  text,
  onClick,
  type = 'button',
}) => {
  return (
    <StyledButtonBlack
      sx={{
        '&:hover': {
          backgroundImage: 'linear-gradient(to right, purple 30%, black  50%)',
        },
      }}
      onClick={onClick}
      type={type}
    >
      {text}
    </StyledButtonBlack>
  );
};
export default ActionTopicButton;
