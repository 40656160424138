import * as React from 'react';
import { useEffect } from 'react';
import { Checkbox, TextField, Tooltip } from '@mui/material';
import Container from '../../components/Container';
import LongMenu from '../../components/LongMenu/LongMenu';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Pagination } from '@mui/material';
import { format } from 'date-fns';

import {
  deleteNftCollectionByIdThunk,
  getAllNftCollectionsThunk,
  updateNftCollectionByIdThunk,
} from '../../redux/nftCollections/thunks';
import {
  MainWrapper,
  NoTopicsWrapper,
  ImageBackground,
  FotoNftCollection,
  NftCollectionName,
  AllNftCollectionsWrapper,
  CreateNftCollectionsWrapper,
  TitleNftCollections,
  NftCollectionsList,
  NftCollectionListWrapper,
  StatusNftCollection,
  InfoNftCollection,
  NftCollectionOwner,
  NoTopicsTitleWrapper,
  StyledTitleNoTopics,
  AvatarWrap,
  CardWrap,
  IconWrap,
  RowWrap,
  SmallText,
  TableItemStyled,
  TableItemWrap,
  TableTitleStyled,
  TableTitleWrap,
  TitleText,
  StyledTDataAction,
} from './styles';
import nftCollectionsSlice from '../../redux/nftCollections/slice';
import { nftCollectionsSelector } from '../../redux/nftCollections/selectors';
import { userSelector } from '../../redux/auth/selectors';
import { INftCollections } from '../../types/nftCollections';
import { LinkWrapper, StyledImage } from '../Nfts/styles';
import { formatIpfsUrl } from '../../utils/formatIpfsUrl';
import ActionTopicButton from '../../components/ActionTopicButton';
import { ICON_TITLE, BTN_TITLE } from '../../helpers/constants';

const NftCollections: React.FC = () => {
  const dispatch = useAppDispatch();
  const nftCollections = useAppSelector(nftCollectionsSelector);
  const authUser: any = useAppSelector(userSelector);
  const isAdmin: boolean = Boolean(authUser?.user?.role);
  const allCollectionsDiv = React.useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [collection, setCollection] = React.useState({});
  const [filterText, setfilterText] = React.useState('');

  const [selectedCollectionId, setSelectedCollectionId] =
    React.useState<string>('');
  const [formEmpty, setFormEmpty] = React.useState<boolean>(true);

  useEffect(() => {
    dispatch(
      getAllNftCollectionsThunk({ limit: 30, page: 1, name: filterText })
    );
  }, [collection, filterText, selectedCollectionId]);

  const handleDelete = async (id: string) => {
    await dispatch(deleteNftCollectionByIdThunk(id));
    setSelectedCollectionId(id);
  };
  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    data: INftCollections
  ) => {
    const newValueCollection = {
      ...data,
      isHot: e.target.checked,
    };
    const newCollection = await dispatch(
      updateNftCollectionByIdThunk(newValueCollection)
    );
    setCollection(newCollection.payload);
  };

  const handleSetFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 3) {
      setfilterText(e.target.value);
    }
    if (e.target.value.length === 0) {
      setfilterText(e.target.value);
    }
  };

  const renderNftCollections = (arr: INftCollections[]) => {
    return (
      <CardWrap style={{ borderCollapse: 'collapse' }}>
        <thead>
          <TableTitleWrap>
            <TableTitleStyled>{'Collection Name'}</TableTitleStyled>
            <TableTitleStyled>{'Collection owner'}</TableTitleStyled>

            <TableTitleStyled style={{ textAlign: 'center' }}>
              {'date'}
            </TableTitleStyled>
            <TableTitleStyled>{'is hot?'}</TableTitleStyled>
          </TableTitleWrap>
        </thead>
        <tbody>
          {arr.map((collection: INftCollections) => {
            return (
              <TableItemWrap key={collection._id}>
                <TableItemStyled>
                  <RowWrap>
                    <AvatarWrap>
                      <StyledImage
                        src={formatIpfsUrl(
                          collection.image_banner_url[0] ||
                            collection.image_url[0]
                        )}
                        alt="nft collection image"
                      />
                    </AvatarWrap>
                    <div>
                      <TitleText>{collection.name}</TitleText>
                      <SmallText
                        style={{ marginTop: '6px', fontWeight: '700' }}
                      >
                        {collection._id}
                      </SmallText>
                    </div>
                  </RowWrap>
                </TableItemStyled>
                <TableItemStyled>
                  <TitleText>
                    {collection.owner ? collection.owner._id : ''}
                  </TitleText>
                </TableItemStyled>

                <TableItemStyled style={{ textAlign: 'center' }}>
                  <TitleText style={{ marginBottom: '6px' }}>
                    {format(new Date(`${collection.createdAt}`), 'dd.MM.yyyy')}
                  </TitleText>
                </TableItemStyled>

                <TableItemStyled>
                  <StatusNftCollection>
                    <Checkbox
                      checked={collection.isHot}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, collection)
                      }
                    />
                    <TableItemStyled>
                      <StyledTDataAction>
                        <LinkWrapper to={``}>
                          <Tooltip
                            title={ICON_TITLE.SHOW_DETAILS}
                            arrow
                          >
                            <ActionTopicButton
                              onClick={() => handleDelete(collection._id)}
                              text={BTN_TITLE.DELETE}
                            />
                          </Tooltip>
                        </LinkWrapper>
                      </StyledTDataAction>
                    </TableItemStyled>
                    {/* <LongMenu
                      userId={collection._id}
                      setSelectedUserId={setSelectedCollectionId}
                      setOpenModal={setOpenModal}
                      isAdmin={isAdmin}
                    /> */}
                  </StatusNftCollection>
                </TableItemStyled>
              </TableItemWrap>
            );
          })}
        </tbody>
      </CardWrap>
    );
    // return arr?.map((collection: INftCollections) => (
    //   <NftCollectionListWrapper
    //     onClick={() => handleFormMemberModalOpen()}
    //     key={collection._id}
    //   >
    //     <InfoNftCollection>
    //       <FotoNftCollection
    //         src={collection.image_url}
    //         alt="collection image"
    //       />
    //       <NftCollectionName>{collection.name}</NftCollectionName>
    //     </InfoNftCollection>
    //     <StatusNftCollection>
    //       <NftCollectionOwner>{collection.owner.email}</NftCollectionOwner>
    //       <Checkbox
    //         checked={collection.isHot}
    //         onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
    //           handleChange(e, collection)
    //         }
    //       />
    //       <LongMenu
    //         userId={collection._id}
    //         setSelectedUserId={setSelectedCollectionId}
    //         setOpenModal={setOpenModal}
    //         isAdmin={isAdmin}
    //       />
    //     </StatusNftCollection>
    //   </NftCollectionListWrapper>
    // ));
  };

  return (
    <Container>
      <MainWrapper>
        <AllNftCollectionsWrapper ref={allCollectionsDiv}>
          <TitleNftCollections>All NFT Collections</TitleNftCollections>
          <TextField
            label="Filter By Collection's Name"
            variant="filled"
            InputLabelProps={{ style: { fontWeight: 400, color: 'white' } }}
            sx={{
              color: 'white',
              width: 300,
              marginBottom: 7,
              fontWeight: 900,
              '& .Mui-focused': {
                backgroundColor: 'transparent',
                color: 'white',
              },
            }}
            onChange={handleSetFilter}
          />
          {nftCollections?.docs.length ? (
            <>
              <NftCollectionsList>
                {renderNftCollections(nftCollections?.docs)}
              </NftCollectionsList>
              <Pagination
                hideNextButton
                hidePrevButton
                defaultPage={nftCollections?.page}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '20px',
                  'li button': {
                    fontFamily: `"Montserrat", sans-serif`,
                    fontWeight: 600,
                    color: '#717384',
                  },
                }}
                page={nftCollections?.page}
                count={nftCollections?.totalPages}
                onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                  dispatch(
                    getAllNftCollectionsThunk({
                      limit: nftCollections?.limit,
                      page: page,
                    })
                  );
                }}
              />
            </>
          ) : (
            <NoTopicsWrapper>
              <ImageBackground></ImageBackground>
              <NoTopicsTitleWrapper>
                <StyledTitleNoTopics>Have no collections</StyledTitleNoTopics>
              </NoTopicsTitleWrapper>
            </NoTopicsWrapper>
          )}
        </AllNftCollectionsWrapper>
      </MainWrapper>
    </Container>
  );
};

export default NftCollections;
