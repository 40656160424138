import { DockSharp } from '@mui/icons-material';
import { createSelector } from 'reselect';
import { RootState } from '..';

const nftsState = (state: RootState) => state.nfts;

/**
 * @description Get list of nfts available on current page (pagination is used)
 */
export const nftsSelector = createSelector(nftsState, ({ nfts }) => nfts);

/**
 * @description Get data of selected nft
 */
export const selectednftSelector = createSelector(
  nftsState,
  ({ selectedNft }) => selectedNft
);
export const modalnftSelector = createSelector(
  nftsState,
  ({ modalNfts }) => modalNfts
);
export const formCleanerSelector = createSelector(
  nftsState,
  ({ formCleaner }) => formCleaner
);
export const nftStatusSelector = createSelector(
  nftsState,
  ({ statuses }) => statuses
);
