import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config/api';
import { API_ROUTES } from '../../config/apiRoutes';
import { IAdminChangePassword } from '../../types/auth';
import { IPaginate } from '../../types/pagination';
import {
  INftCollectionsListQueryData,
  INftCollections,
  GetCollectionQuery,
} from '../../types/nftCollections';

/**
 * @description Dispatch this thunk to get list of users
 * @param {IUsersListQueryData} queryData - Object containing limit and page (used for pagination)
 */
export const getAllNftCollectionsThunk = createAsyncThunk<
  IPaginate<INftCollections>,
  GetCollectionQuery
>(
  'nftCollections/getAllNftCollectionsThunk',
  async (
    queryData: INftCollectionsListQueryData | undefined,
    { rejectWithValue }
  ) => {
    const { limit = 30, page = 1, name = '' } = queryData ?? {};
    try {
      const { data } = await API.get(
        API_ROUTES.NFT_COLLECTIONS.GET_LIST(limit, page, name)
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
/**
 * @description Dispatch this thunk to get user by id
 * @param {string} id - String id to find current user
 */
export const getNftCollectionByIdThunk = createAsyncThunk(
  'nftCollections/getNftCollectionByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.NFT_COLLECTIONS.GET_BY_ID(id));
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/**
 * @description Dispatch this thunk to get user by id
 * @param {string} id - String id to find current user
 */
export const updateNftCollectionByIdThunk = createAsyncThunk(
  'nftCollections/updateNftCollectionByIdThunk',
  async (values: INftCollections, { rejectWithValue }) => {
    try {
      const { data } = await API.put(
        API_ROUTES.NFT_COLLECTIONS.UPDATE(values._id),
        {
          ...values,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/**
 * @description Dispatch this thunk to delete NFT collection by id
 * @param {string} id - String id to find current NFT collection
 */
export const deleteNftCollectionByIdThunk = createAsyncThunk(
  'nftCollections/deleteNftCollectionByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(
        API_ROUTES.NFT_COLLECTIONS.DELETE_BY_ID(id)
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
