import {
  ClickAwayListener,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chain, useNetwork, useSwitchNetwork } from 'wagmi';
import { switchNetwork } from '@wagmi/core';

import {
  AppBarButton,
  NetworkBox,
  NetworkIcon,
  NetworkList,
  NetworkName,
  NetworkPopper,
} from './styles';
import {
  setNetwork,
  setSelectedNetwork,
  setNetworkError,
} from '../../redux/network/slice';
import { ExpandLess, ExpandMore } from '../ExpandMoreAndLess';
import { Border, Color } from '../../helpers/themeStyles';
import { networkSelector } from '../../redux/network/selectors';

const NetworkSelector = ({ isConnected }: { isConnected: boolean }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  // Error can be checked and showed global pop up, isLoading & pendingChainId can be used for show "<Loader> aprove in your wallet"
  const [chain, setChain] = useState<Chain>();
  const { error, switchNetwork: hookSwitchNetwork } = useSwitchNetwork({
    onSuccess(chain) {
      setChain(chain);
      dispatch(setNetwork(chain));
    },
    onError(error, vars, context) {
      dispatch(setNetworkError(true));
      console.error(error, vars, context);
    },
  });
  const chainFromRedux = useSelector(networkSelector);
  const { chain: chainWagmi, chains } = useNetwork();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (chainWagmi) {
      setChain(chainWagmi);
    }
    if (chainFromRedux) {
      setChain(chainFromRedux);
    }
  }, [chainWagmi, chainFromRedux]);
  const isDevelopmentMode = process.env.NODE_ENV === 'development';
  const generateNetworksArray = () => {
    if (isDevelopmentMode) {
      return chains;
    }
    return chains.filter((network) => !network.testnet);
  };
  const switchCoreNetwork = async (chainId: number, chain: Chain) => {
    try {
      switchNetwork({ chainId })
        .then((chain) => setChain(chain))
        .catch((err) => dispatch(setNetworkError(true)));
      dispatch(setNetwork(chain));
    } catch (err) {
      dispatch(setNetworkError(true));
    }
  };

  if (chain && isConnected) {
    return (
      <>
        <AppBarButton
          aria-haspopup="true"
          onClick={(e) => {
            setIsOpen(!isOpen);
            handleClick(e);
          }}
          sx={{
            [theme.breakpoints.down('mobile')]: {
              border: isOpen ? Border.ACCENT_BORDER : Border.TRANSPARENT_BORDER,
              backgroundImage: !isOpen
                ? `linear-gradient(#251929, #251929), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`
                : null,
              backgroundOrigin: !isOpen ? 'border-box' : null,
              backgroundClip: !isOpen ? 'padding-box, border-box' : null,
            },
          }}
        >
          <NetworkIcon
            src={
              [1, 5].includes(chain?.id)
                ? 'https://res.cloudinary.com/dhk15xaeq/image/upload/v1670343505/%D0%91%D0%B5%D0%B7_%D0%BD%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_vxjkle.png'
                : 'https://pancakeswap.finance/images/chains/56.png'
            }
          />
          {!isMobile && (
            <NetworkName variant="subtitle1">
              {chainWagmi?.unsupported ? 'Unsupported network' : chain?.name}
            </NetworkName>
          )}

          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </AppBarButton>
        <NetworkPopper
          open={isOpen}
          anchorEl={anchorEl}
          placement="bottom-end"
          onReset={undefined}
          onResetCapture={undefined}
        >
          <ClickAwayListener
            onClickAway={() => {
              setIsOpen(false);
              setAnchorEl(null);
            }}
          >
            <NetworkBox>
              <NetworkList>
                {generateNetworksArray().map((network) => {
                  return (
                    <ListItemButton
                      key={network.id}
                      sx={{ p: 0 }}
                      onClick={() => {
                        hookSwitchNetwork
                          ? hookSwitchNetwork?.(network.id)
                          : switchCoreNetwork(network.id, network);
                        setIsOpen(false);
                        setAnchorEl(null);
                        dispatch(setSelectedNetwork(network?.name));
                      }}
                      disabled={network.id === chain?.id}
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: '16px' }}>
                        <NetworkIcon
                          src={
                            [1, 5].includes(network.id)
                              ? 'https://res.cloudinary.com/dhk15xaeq/image/upload/v1670343505/%D0%91%D0%B5%D0%B7_%D0%BD%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_vxjkle.png'
                              : 'https://pancakeswap.finance/images/chains/56.png'
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={network.name} />
                    </ListItemButton>
                  );
                })}
              </NetworkList>
            </NetworkBox>
          </ClickAwayListener>
        </NetworkPopper>
      </>
    );
  }
  return <></>;
};

export default NetworkSelector;
