import { mainnet, goerli, Chain } from 'wagmi/chains';

export const chains: Chain[] = [
  mainnet,
  goerli,
  {
    id: 56,
    name: 'BNB Smart Chain',
    network: 'binance',
    nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
    rpcUrls: {
      default: { http: ['https://bsc-dataseed1.binance.org'] },
      public: { http: ['https://bsc-dataseed1.binance.org'] },
    },
  },
  {
    name: 'BSC Testnet',
    id: 97,
    network: 'binance_test',
    testnet: true,
    nativeCurrency: { name: 'tBNB', decimals: 18, symbol: 'tBNB' },
    rpcUrls: {
      default: { http: ['https://data-seed-prebsc-1-s1.binance.org:8545'] },
      public: { http: ['https://data-seed-prebsc-1-s1.binance.org:8545'] },
    },
  },
];
