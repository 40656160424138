import { createSlice } from '@reduxjs/toolkit';
import { IStoreStatuses, STATUS_ENUM } from '../../types/statuses';
import { StatusGenerator } from '../../utils/StatusGenerator';
import { getAllNftsThunk, getNftByIdThunk, deleteNftByIdThunk } from './thunks';
import { IPaginate } from '../../types/pagination';
import { INft } from '../../types/nft';

interface IState {
  nfts: IPaginate | null;
  selectedNft: INft | null;
  statuses: IStoreStatuses;
  modalNfts: boolean;
  formCleaner: boolean;
}

const initialState: IState = {
  nfts: null,
  selectedNft: null,
  statuses: StatusGenerator.generateStatuses([
    getAllNftsThunk.typePrefix,
    getNftByIdThunk.typePrefix,
    deleteNftByIdThunk.typePrefix,
  ]),
  modalNfts: false,
  formCleaner: false,
};

const nftsSlice = createSlice({
  name: 'nfts',
  initialState,
  reducers: {
    setelectednftCollection: (state, action) => {
      state.selectedNft = action.payload.data ?? null;
    },
    setnftCollections: (state, action) => {
      state.nfts = action.payload.data ?? null;
    },
    modalCollections: (state, action) => {
      state.modalNfts = action.payload ?? false;
    },
    formCleaner: (state, action) => {
      state.formCleaner = action.payload ?? false;
    },
    resetStatus: (state) => {
      state.statuses = initialState.statuses;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllNftsThunk.pending, (state) => {
        state.statuses[getAllNftsThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.PENDING
        );
      })
      .addCase(getAllNftsThunk.rejected, (state, { error }) => {
        state.statuses[getAllNftsThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          error.message
        );
      })
      .addCase(getAllNftsThunk.fulfilled, (state, { payload }) => {
        state.statuses[getAllNftsThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.FULFILLED
        );
        state.nfts = payload;
      })
      .addCase(getNftByIdThunk.pending, (state) => {
        state.statuses[getNftByIdThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.PENDING
        );
      })
      .addCase(getNftByIdThunk.rejected, (state, { error }) => {
        state.statuses[getNftByIdThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          error.message
        );
      })
      .addCase(getNftByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getNftByIdThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.FULFILLED
        );
        state.selectedNft = payload;
      })
      .addCase(deleteNftByIdThunk.pending, (state) => {
        state.statuses[deleteNftByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(deleteNftByIdThunk.rejected, (state, { error }) => {
        state.statuses[deleteNftByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(deleteNftByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[deleteNftByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      }),
});

export default nftsSlice;
