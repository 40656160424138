import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth/slice';
import postSlice from './posts/slice';
import usersSlice from './users/slice';
import nftCollectionsSlice from './nftCollections/slice';
import nftsSlice from './nft/slice';
import tokensSlice from './tokens/slice';
import walletSlice from './walletconnect/slice';
import swapSlice from './swap/slice';
import networkSlice from './network/slice';
import projectsSlice from './projects/slice';

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [postSlice.name]: postSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [nftCollectionsSlice.name]: nftCollectionsSlice.reducer,
    [projectsSlice.name]: projectsSlice.reducer,
    [nftsSlice.name]: nftsSlice.reducer,
    [tokensSlice.name]: tokensSlice.reducer,
    [walletSlice.name]: walletSlice.reducer,
    [swapSlice.name]: swapSlice.reducer,
    [networkSlice.name]: networkSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
