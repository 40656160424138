import { createSlice } from '@reduxjs/toolkit';
import { IStoreStatuses, STATUS_ENUM } from '../../types/statuses';
import { StatusGenerator } from '../../utils/StatusGenerator';
import {
  deleteNftCollectionByIdThunk,
  getAllNftCollectionsThunk,
  getNftCollectionByIdThunk,
  updateNftCollectionByIdThunk,
} from './thunks';
import { INftCollections } from '../../types/nftCollections';
import { IPaginate } from '../../types/pagination';

interface IState {
  nftCollections: IPaginate | null;
  selectednftCollection: INftCollections | null;
  statuses: IStoreStatuses;
  modalCollections: boolean;
  formCleaner: boolean;
}

const initialState: IState = {
  nftCollections: null,
  selectednftCollection: null,
  statuses: StatusGenerator.generateStatuses([
    getAllNftCollectionsThunk.typePrefix,
    getNftCollectionByIdThunk.typePrefix,
    deleteNftCollectionByIdThunk.typePrefix,
  ]),
  modalCollections: false,
  formCleaner: false,
};

const nftCollectionsSlice = createSlice({
  name: 'nftCollections',
  initialState,
  reducers: {
    setelectednftCollection: (state, action) => {
      state.selectednftCollection = action.payload.data ?? null;
    },
    setnftCollections: (state, action) => {
      state.nftCollections = action.payload.data ?? null;
    },
    modalCollections: (state, action) => {
      state.modalCollections = action.payload ?? false;
    },
    formCleaner: (state, action) => {
      state.formCleaner = action.payload ?? false;
    },
    resetStatus: (state) => {
      state.statuses = initialState.statuses;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllNftCollectionsThunk.pending, (state) => {
        state.statuses[getAllNftCollectionsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getAllNftCollectionsThunk.rejected, (state, { error }) => {
        state.statuses[getAllNftCollectionsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getAllNftCollectionsThunk.fulfilled, (state, { payload }) => {
        state.statuses[getAllNftCollectionsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.nftCollections = payload;
      })
      .addCase(getNftCollectionByIdThunk.pending, (state) => {
        state.statuses[getNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getNftCollectionByIdThunk.rejected, (state, { error }) => {
        state.statuses[getNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getNftCollectionByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectednftCollection = payload;
      })
      .addCase(updateNftCollectionByIdThunk.pending, (state) => {
        state.statuses[getNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(updateNftCollectionByIdThunk.rejected, (state, { error }) => {
        state.statuses[getNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(updateNftCollectionByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectednftCollection = payload;
      })
      .addCase(deleteNftCollectionByIdThunk.pending, (state) => {
        state.statuses[deleteNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(deleteNftCollectionByIdThunk.rejected, (state, { error }) => {
        state.statuses[deleteNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(deleteNftCollectionByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[deleteNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      }),
});

export default nftCollectionsSlice;
