import { useState } from 'react';
import { useFormik } from 'formik';
import { initialValues, Props } from './config';
import { Modal, Box, IconButton, Stack } from '@mui/material';
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';

import { useAccount, useConnect, useNetwork } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';
import { chains } from '../../utils/chains';
import { Close } from '@mui/icons-material';
import { Color, TextSize } from '../../helpers/themeStyles';
import CoinBaseIcon from '../../shared/wallet-connect/coinbaseWalletIcon.svg';
import MetaMaskIcon from '../../shared/wallet-connect/metamask.png';
import WalletConnectIcon from '../../shared/wallet-connect/walletConnect.png';
import {
  StyledModalContent,
  ContentWrap,
  StyledModalTitle,
  StyledConnectItem,
  ConnectItemWrap,
  StyledAvatar,
  StyledModalFooter,
  StyledModalFooterText,
  StyledModalLink,
} from './styles';
import { walletPopUpIsOpenSelector } from '../../redux/walletconnect/selectors';
import WelcomeNftPopup from '../WelcomeNftPopup';
import { ContractWriteFees } from '../../helpers/contractWrite';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';

const ConnectWalletModal = (props: Props) => {
  const { address, isConnected } = useAccount();
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { chain } = useNetwork();
  const { connect } = useConnect({
    onSuccess: () => {
      setIsAuthOpen(true);
    },
  });
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConnect = (wallet: string) => {
    let connector;
    switch (wallet) {
      case 'metamask':
        connector = new MetaMaskConnector({
          chains,
        });
        break;
      case 'coinbase':
        connector = new CoinbaseWalletConnector({
          chains,
          options: { appName: '' },
        });
        break;
      case 'walletconnect':
        connector = new WalletConnectConnector({
          chains,
          options: { qrcode: true } as never,
        });
        break;
      default:
        console.error('Unsupported wallet chosen!');
        break;
    }
    if (connector) {
      console.log(`${connector} connect`);
      connect({ connector });
    }
    handleClose();

    props.handleFeeModalClose();
  };

  return (
    <>
      <Modal
        open={props.feeModal}
        onClose={props.handleFeeModalClose}
      >
        <StyledModalContent>
          <ContentWrap>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                padding: '16px',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
              }}
            >
              <StyledModalTitle style={{ fontSize: TextSize.MIDDLE }}>
                Connect a wallet
              </StyledModalTitle>
              <IconButton
                sx={{ p: 0, color: Color.WHITE }}
                onClick={props.handleFeeModalClose}
              >
                <Close />
              </IconButton>
            </Stack>
            <Stack
              style={{ padding: '16px' }}
              gap={1.5}
            >
              <StyledConnectItem onClick={() => handleConnect('metamask')}>
                <ConnectItemWrap>
                  <StyledAvatar
                    src={MetaMaskIcon}
                    alt="metamask"
                  />
                  <StyledModalTitle>MetaMask</StyledModalTitle>
                </ConnectItemWrap>
              </StyledConnectItem>
              <StyledConnectItem onClick={() => handleConnect('coinbase')}>
                <ConnectItemWrap>
                  <StyledAvatar
                    src={CoinBaseIcon}
                    alt="metamask"
                  />
                  <StyledModalTitle>Coinbase Wallet</StyledModalTitle>
                </ConnectItemWrap>
              </StyledConnectItem>
              <StyledConnectItem onClick={() => handleConnect('walletconnect')}>
                <ConnectItemWrap>
                  <StyledAvatar
                    src={WalletConnectIcon}
                    alt="metamask"
                  />
                  <StyledModalTitle>Connect a wallet</StyledModalTitle>
                </ConnectItemWrap>
              </StyledConnectItem>
            </Stack>
            <StyledModalFooter></StyledModalFooter>
          </ContentWrap>
        </StyledModalContent>
      </Modal>
      <WelcomeNftPopup
        isOpen={isAuthOpen}
        onClose={() => {
          setIsAuthOpen(false);
        }}
        isConnecting
      />
    </>
  );
};

export default ConnectWalletModal;
