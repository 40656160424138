import { createSlice } from '@reduxjs/toolkit';
import { Chain } from 'wagmi';

interface IState {
  network:
    | (Chain & {
        unsupported?: boolean | undefined;
      })
    | undefined;
  error: boolean;
  selectedNetwork: string;
}

const initialState: IState = {
  network: undefined,
  error: false,
  selectedNetwork: '',
};

const networkSlice = createSlice({
  name: 'network',
  initialState: initialState,
  reducers: {
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    setSelectedNetwork: (state, action) => {
      state.selectedNetwork = action.payload;
    },
    setNetworkError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { setNetwork, setNetworkError, setSelectedNetwork } =
  networkSlice.actions;

export default networkSlice;
