import { Stack } from '@mui/material';
import { useNetwork } from 'wagmi';
import React, { useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useScroll from '../../helpers/useScroll';
import VideoPlayer from '../VideoPlayer';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import {
  StyledProjectPageWrapper,
  StyledProjectDetailsContainer,
  StyledProjectFinInfoContainer,
  SocialContainer,
  SocialItem,
  SocialItems,
  SocialTitle,
  StyledHeadingContainer,
  StyledLogo,
  StyledNetworkName,
  StyledProjectBanner,
  StyledProjectChain,
  StyledProjectDescription,
  StyledProjectName,
  StyledTab,
  StyledTabs,
  StyledWebsiteLink,
  TokenDetailsBlock,
  TokenDetailsName,
  TokenDetailssValue,
  TokenSaleContainer,
  StyledFinancialInfoContainer,
  StyledFinancialInfoLine,
  StyledFinancialInfoLineLabel,
  StyledFinancialInfoLineValue,
  StyledChapter,
  StyledTeamContainer,
  StyledTeamCard,
  StyledNavWrapper,
  StyledNavScrolledWrapper,
  StyledNavItem,
  ChangeStatusButton,
  StyledHeadingChapter,
  StyledLinkedInLink,
  StyledTeamText,
  ProjectNavBarWrapper,
  ItemIndicator,
  StyledImg,
} from './styles';
import style from './markdown-styles.module.css';
import { NetworkIcon } from '../../components/NetworkSelector/styles';
import TwitterIcon from '../../svg/TwitterIcon';
import TelegramIcon from '../../svg/TelegramIcon';
import { TabPanel, a11yProps } from './CustomProjectTab';
import { Color } from '../../helpers/themeStyles';
import { getProjectByIdThunk } from '../../redux/projects/thunks';
import { selectedProjectSelector } from '../../redux/projects/selectors';
import { GitHub } from '@mui/icons-material';
import { formatIpfsUrl } from '../../utils/formatIpfsUrl';
import ChangeStatusModal from '../ChangeStatusModal/ChangeStatusModal';
import DeleteModal from '../DeleteModal/DeleteModal';
import dateFortmatter from '../../helpers/dateFormatter';
import { networks } from '../../helpers/networks';

const ProjectPage = () => {
  const params = useParams();
  const [tab, setTab] = useState(0);
  const [mainTab, setMainTab] = useState(0);
  const [statusModal, setStatusModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const project = useSelector(selectedProjectSelector);
  const disableButton = Boolean(
    project?.status === 'current' ||
      project?.status === 'upcoming' ||
      project?.status === 'closed'
  );

  const youtubeRegExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const videoMatch = project?.videoLink.match(youtubeRegExp);
  const videoID =
    videoMatch && videoMatch[2].length === 11 ? videoMatch[2] : '';
  const { id } = params;
  const mockedProject = {
    projectChain: 5,
    finInfoLabels: {
      goal: 'Fundraise goal',
      allocation: 'Allocation',
      pricePerToken: 'Price per token',
      saleStart: 'Token Sale Starts',
    },
    navBarItems: [
      'Product',
      'Problem',
      'Tokenomics',
      'Roadmap',
      'Business Model',
      'Team',
      'Investors',
    ],
  };
  const isTeamPresented =
    project?.teamMembers?.length! > 0 && project?.teamMembers[0].name !== '';
  const presentedNavBarItems = isTeamPresented
    ? mockedProject.navBarItems
    : mockedProject.navBarItems.filter((i) => i !== 'Team');

  const { chains } = useNetwork();
  const [navBarRoof, setNavBarRoof] = useState(false);
  const [activeTab, setActiveTab] = useState(mockedProject.navBarItems[0]);

  const {
    problem,
    product,
    businessmodel,
    tokenomics,
    roadmap,
    team,
    investors,
    scrollToAnchor,
  } = useScroll();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleMainChange = (event: React.SyntheticEvent, newValue: number) => {
    setMainTab(newValue);
  };
  const handleClick = (i: string) => {
    setActiveTab(i);
  };
  const navItemRefs = useMemo(
    () =>
      presentedNavBarItems.map(() => React.createRef<HTMLParagraphElement>()),
    [presentedNavBarItems]
  );

  useEffect(() => {
    const element = document.querySelector('#nav-bar-anchor');
    window.addEventListener('scroll', () => {
      const elementRoof: any = element?.getBoundingClientRect()?.y;
      elementRoof <= 30 ? setNavBarRoof(true) : setNavBarRoof(false);
    });
  });
  useEffect(() => {
    dispatch(getProjectByIdThunk(id || ''));
  }, [dispatch, id]);
  const navBar = presentedNavBarItems.map((i, index) => {
    const isActive = i === activeTab;
    return (
      <ProjectNavBarWrapper
        onClick={() => {
          scrollToAnchor(i);
          handleClick(i);
        }}
        key={index}
      >
        <StyledNavItem ref={navItemRefs[index]}>{i}</StyledNavItem>
        {isActive && (
          <ItemIndicator
            width={navItemRefs[index].current?.getBoundingClientRect().width}
          />
        )}
      </ProjectNavBarWrapper>
    );
  });

  const handleStatusModalClose = () => {
    setStatusModal(false);
  };
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };
  return (
    <>
      <StyledProjectPageWrapper>
        <StyledProjectDetailsContainer>
          <StyledTabs
            value={mainTab}
            onChange={handleMainChange}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
          >
            <StyledTab
              label={'Project preview'}
              {...a11yProps(0)}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: `${Color.DARK_PURPLE_OPACITY_LIGHT}!important`,
                },
              }}
            />
            <StyledTab
              label={'Project information'}
              {...a11yProps(1)}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: `${Color.DARK_PURPLE_OPACITY_LIGHT}!important`,
                },
              }}
            />
          </StyledTabs>
          <TabPanel
            value={mainTab}
            index={0}
          >
            <StyledHeadingContainer>
              <StyledLogo
                src={project?.logo[1] && formatIpfsUrl(project?.logo[1]!)}
                width="250px"
              />
              <Stack
                justifyContent="space-between"
                width="100%"
              >
                <StyledProjectName>Project Information</StyledProjectName>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                >
                  <SocialTitle>{'Project Title:'}</SocialTitle>
                  <StyledProjectDescription>
                    {project?.projectTitle}
                  </StyledProjectDescription>
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                >
                  <SocialTitle>{'Project Description:'}</SocialTitle>
                  <StyledProjectDescription>
                    {project?.information}
                  </StyledProjectDescription>
                </Stack>
              </Stack>
            </StyledHeadingContainer>

            <StyledProjectBanner
              bannerLink={
                project?.heroPicture[1] &&
                formatIpfsUrl(project?.heroPicture[1]!)
              }
            >
              <StyledProjectChain>
                <NetworkIcon
                  src={
                    [1, 5].includes(mockedProject.projectChain)
                      ? 'https://res.cloudinary.com/dhk15xaeq/image/upload/v1670343505/%D0%91%D0%B5%D0%B7_%D0%BD%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_vxjkle.png'
                      : 'https://pancakeswap.finance/images/chains/56.png'
                  }
                />
                <StyledNetworkName>
                  {
                    chains.filter(
                      (chain) => chain.id === mockedProject.projectChain
                    )[0]?.name
                  }
                </StyledNetworkName>
              </StyledProjectChain>
            </StyledProjectBanner>
            <SocialContainer>
              <Stack alignItems="baseline">
                <StyledChapter ref={product}>{'Social Media'}</StyledChapter>
                <SocialContainer>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                  >
                    <SocialItems>
                      {project?.socialMedia['twitter'] && (
                        <SocialItem
                          href={project?.socialMedia['twitter']}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          <TwitterIcon />
                        </SocialItem>
                      )}
                      {project?.socialMedia['telegram'] && (
                        <SocialItem
                          href={project?.socialMedia['telegram']}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          <TelegramIcon />
                        </SocialItem>
                      )}
                      {project?.socialMedia['github'] && (
                        <SocialItem
                          href={project?.socialMedia['github']}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          <GitHub sx={{ fill: Color.WHITE }} />
                        </SocialItem>
                      )}
                    </SocialItems>
                  </Stack>
                </SocialContainer>
              </Stack>
              <Stack>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                >
                  <SocialTitle>{'Website'}:</SocialTitle>
                  <StyledWebsiteLink href={project?.socialMedia.website}>
                    {project?.socialMedia.website}
                  </StyledWebsiteLink>
                </Stack>
              </Stack>
            </SocialContainer>
            <StyledTabs
              value={tab}
              onChange={handleChange}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              <StyledTab
                label={'Description'}
                {...a11yProps(0)}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: `${Color.DARK_PURPLE_OPACITY_LIGHT}!important`,
                  },
                }}
              />
              <StyledTab
                label={'Token Sale Details'}
                {...a11yProps(1)}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: `${Color.DARK_PURPLE_OPACITY_LIGHT}!important`,
                  },
                }}
              />
            </StyledTabs>
            <TabPanel
              value={tab}
              index={0}
            >
              <div id="nav-bar-anchor"></div>
              {!navBarRoof && <StyledNavWrapper>{navBar}</StyledNavWrapper>}
              {navBarRoof && (
                <StyledNavScrolledWrapper>{navBar}</StyledNavScrolledWrapper>
              )}
              {project?.videoLink && videoID && <VideoPlayer id={videoID} />}
              <StyledChapter ref={product}>{'Product'}</StyledChapter>
              <ReactMarkdown className={style.reactMarkDown}>
                {(project?.navigation.length &&
                  project?.navigation[0].details) ||
                  ''}
              </ReactMarkdown>
              {project?.navigation.length &&
                project?.navigation[0].picture[1] && (
                  <StyledImg
                    src={formatIpfsUrl(project?.navigation[0].picture[1])}
                  />
                )}
              <StyledChapter ref={problem}>{'Problem'}</StyledChapter>
              <ReactMarkdown className={style.reactMarkDown}>
                {(project?.navigation.length &&
                  project?.navigation[1].details) ||
                  ''}
              </ReactMarkdown>
              {project?.navigation.length &&
                project?.navigation[1].picture[1] && (
                  <StyledImg
                    src={formatIpfsUrl(project?.navigation[1].picture[1])}
                  />
                )}
              <StyledChapter ref={tokenomics}>{'Token'}</StyledChapter>
              <ReactMarkdown className={style.reactMarkDown}>
                {(project?.navigation.length &&
                  project?.navigation[2].details) ||
                  ''}
              </ReactMarkdown>
              {project?.navigation.length &&
                project?.navigation[2].picture[1] && (
                  <StyledImg
                    src={formatIpfsUrl(project?.navigation[2].picture[1])}
                  />
                )}
              <StyledChapter ref={roadmap}>{'Roadmap'}</StyledChapter>
              <ReactMarkdown className={style.reactMarkDown}>
                {(project?.navigation.length &&
                  project?.navigation[3].details) ||
                  ''}
              </ReactMarkdown>
              {project?.navigation.length &&
                project?.navigation[3].picture[1] && (
                  <StyledImg
                    src={formatIpfsUrl(project?.navigation[3].picture[1])}
                  />
                )}
              <StyledChapter ref={businessmodel}>
                {'Business Model'}
              </StyledChapter>
              <ReactMarkdown className={style.reactMarkDown}>
                {(project?.navigation.length &&
                  project?.navigation[4].details) ||
                  ''}
              </ReactMarkdown>
              {project?.navigation.length &&
                project?.navigation[4].picture[1] && (
                  <StyledImg
                    src={formatIpfsUrl(project?.navigation[4].picture[1])}
                  />
                )}
              {isTeamPresented && (
                <StyledChapter ref={team}>Team</StyledChapter>
              )}
              {isTeamPresented &&
                project?.teamMembers?.map((item) => {
                  if (
                    item.name ||
                    item.position ||
                    item.about ||
                    item.linkedIn
                  ) {
                    return (
                      <StyledTeamContainer>
                        <StyledTeamCard>
                          {item.name && (
                            <StyledHeadingChapter>
                              {item.name}
                            </StyledHeadingChapter>
                          )}
                          {item.position && (
                            <StyledTeamText>{item.position}</StyledTeamText>
                          )}
                          {item.about && (
                            <StyledTeamText>{item.about}</StyledTeamText>
                          )}
                          {item.linkedIn && (
                            <StyledLinkedInLink
                              href={item.linkedIn}
                              rel="noreferrer noopener"
                              target="_blank"
                            >
                              <LinkedInIcon
                                sx={{ fill: Color.LINKEDIN_COLOR }}
                              />{' '}
                              {'LinkedIn'}
                            </StyledLinkedInLink>
                          )}
                        </StyledTeamCard>
                      </StyledTeamContainer>
                    );
                  }
                  return null;
                })}
              <StyledChapter ref={investors}>{'Investors'}</StyledChapter>
              <ReactMarkdown className={style.reactMarkDown}>
                {(project?.navigation.length &&
                  project?.navigation[5].details) ||
                  ''}
              </ReactMarkdown>
              {project?.navigation.length &&
                project?.navigation[5].picture[1] && (
                  <StyledImg
                    src={formatIpfsUrl(project?.navigation[5].picture[1])}
                  />
                )}
            </TabPanel>
            <TabPanel
              value={tab}
              index={1}
            >
              <TokenSaleContainer>
                <TokenDetailsBlock>
                  <TokenDetailsName>{'Sale Price'}</TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.tokenSaleDetails.price}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
                <TokenDetailsBlock>
                  <TokenDetailsName>
                    {'Token Allocation For Sale'}
                  </TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.tokenSaleDetails.allocation}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
                <TokenDetailsBlock>
                  <TokenDetailsName>{'Min allocation'}</TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.tokenSaleDetails.minAllocation}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
                <TokenDetailsBlock>
                  <TokenDetailsName>{'Max allocation'}</TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.tokenSaleDetails.maxAllocation}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
                <TokenDetailsBlock>
                  <TokenDetailsName>{'Total Supply'}</TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.tokenSaleDetails.total_supply}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
              </TokenSaleContainer>
            </TabPanel>
          </TabPanel>
          <TabPanel
            value={mainTab}
            index={1}
          >
            <TokenSaleContainer>
              <StyledProjectName>Project OWNER Information</StyledProjectName>
              <Stack
                flexDirection="row"
                alignItems="center"
              >
                <SocialTitle>{'Project Owner Name:'}</SocialTitle>
                <StyledProjectDescription>
                  {project?.contactName}
                </StyledProjectDescription>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
              >
                <SocialTitle>{'Owner Telegram / WhatsApp:'}</SocialTitle>
                <StyledProjectDescription>
                  {project?.socialMedia.telegram}
                </StyledProjectDescription>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
              >
                <SocialTitle>{'Owner E-Mail'}:</SocialTitle>
                <StyledWebsiteLink href={`mailto: ${project?.contactEmail}`}>
                  {project?.contactEmail}
                </StyledWebsiteLink>
              </Stack>
              <StyledProjectName>Project Information</StyledProjectName>

              <TokenDetailsBlock>
                <TokenDetailsName>{'Project Title:'}</TokenDetailsName>
                <TokenDetailssValue>{project?.projectTitle}</TokenDetailssValue>
              </TokenDetailsBlock>
              {project?.categories.length && (
                <TokenDetailsBlock>
                  <TokenDetailsName>{'Categories'}</TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.categories.join()}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              {project?.whitepaper && (
                <TokenDetailsBlock>
                  <TokenDetailsName>{'Whitepaper URL'}</TokenDetailsName>
                  <TokenDetailssValue>{project?.whitepaper}</TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              {project?.pitchDeck && (
                <TokenDetailsBlock>
                  <TokenDetailsName>{'Pitch Deck URL'}</TokenDetailsName>
                  <TokenDetailssValue>{project?.pitchDeck}</TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              {project?.socialMedia.twitter && (
                <TokenDetailsBlock>
                  <TokenDetailsName>{'Twitter URL'}</TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.socialMedia.twitter}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              {project?.socialMedia.github && (
                <TokenDetailsBlock>
                  <TokenDetailsName>{'Github URL'}</TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.socialMedia.github}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              <TokenDetailsBlock>
                <TokenDetailsName>{'State of Development'}</TokenDetailsName>
                <TokenDetailssValue>
                  {project?.developmentState}
                </TokenDetailssValue>
              </TokenDetailsBlock>
              {project?.hardCommitments && (
                <TokenDetailsBlock>
                  <TokenDetailsName>
                    {'How much have already raised? (hard commitments)'}
                  </TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.hardCommitments}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              <TokenDetailsBlock>
                <TokenDetailsName>
                  {'How much looking to raise with Planetex DeFi? ($)'}
                </TokenDetailsName>
                <TokenDetailssValue>
                  {project?.poolInformation.toRise}
                </TokenDetailssValue>
              </TokenDetailsBlock>
              <TokenDetailsBlock>
                <TokenDetailsName>
                  {
                    'How much looking to raise in total excluding Planetex DeFi? ($)'
                  }
                </TokenDetailsName>
                <TokenDetailssValue>
                  {project?.poolInformation.toRiseTotal}
                </TokenDetailssValue>
              </TokenDetailsBlock>
              <TokenDetailsBlock>
                <TokenDetailsName>{'Flexible  amount?'}</TokenDetailsName>
                <TokenDetailssValue>
                  {project?.poolInformation.amountFlexible ? 'Yes' : 'No'}
                </TokenDetailssValue>
              </TokenDetailsBlock>
              {project?.poolInformation.amountFlexible && (
                <TokenDetailsBlock>
                  <TokenDetailsName>
                    {' % of the fundraising goal'}
                  </TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.poolInformation.lowestPercentageOfFundraisingGoal}{' '}
                    %
                  </TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              <TokenDetailsBlock>
                <TokenDetailsName>{'Date of launch'}</TokenDetailsName>
                <TokenDetailssValue>
                  {project?.poolInformation.launchDate
                    ? dateFortmatter(
                        project?.poolInformation.launchDate!,
                        'dd MMMM yyyy'
                      )
                    : 'not settled'}
                </TokenDetailssValue>
              </TokenDetailsBlock>
              <TokenDetailsBlock>
                <TokenDetailsName>
                  {'Flexible  date of launch?'}
                </TokenDetailsName>
                <TokenDetailssValue>
                  {project?.poolInformation.dateFlexible ? 'Yes' : 'No'}
                </TokenDetailssValue>
              </TokenDetailsBlock>
              <TokenDetailsBlock>
                <TokenDetailsName>
                  {'Which network is preferred for choice?'}
                </TokenDetailsName>
                <TokenDetailssValue>
                  {networks[project?.poolInformation.chain!]}
                </TokenDetailssValue>
              </TokenDetailsBlock>
              <TokenDetailsBlock>
                <TokenDetailsName>
                  {
                    'Is there plans to launch on any other IDO platforms apart from Planetex DeFi'
                  }
                </TokenDetailsName>
                <TokenDetailssValue>
                  {project?.poolInformation.planingOtherLaunch ? 'Yes' : 'No'}
                </TokenDetailssValue>
              </TokenDetailsBlock>
              <TokenDetailsBlock>
                <TokenDetailsName>
                  {'Open to exclusive IDO with Planetex DeFi?'}
                </TokenDetailsName>
                <TokenDetailssValue>
                  {project?.poolInformation.exclusiveOpen ? 'Yes' : 'No'}
                </TokenDetailssValue>
              </TokenDetailsBlock>
              {project?.poolInformation.marketingAgencies && (
                <TokenDetailsBlock>
                  <TokenDetailsName>
                    {'Working with any marketing agencies?'}
                  </TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.poolInformation.marketingAgencies}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              {project?.poolInformation.supportingInfluencer && (
                <TokenDetailsBlock>
                  <TokenDetailsName>
                    {'Have an influencer network supporting?'}
                  </TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.poolInformation.supportingInfluencer}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              {project?.poolInformation.otherPlatforms && (
                <TokenDetailsBlock>
                  <TokenDetailsName>
                    {'What other platforms? Are they confirmed?'}
                  </TokenDetailsName>
                  <TokenDetailssValue>
                    {project?.poolInformation.otherPlatforms}
                  </TokenDetailssValue>
                </TokenDetailsBlock>
              )}
              <TokenDetailsBlock>
                <TokenDetailsName>
                  {'Token Distribution Date (UTC)'}
                </TokenDetailsName>
                <TokenDetailssValue>
                  {project?.poolInformation.tokenDistributionDate
                    ? dateFortmatter(
                        project?.poolInformation.tokenDistributionDate!,
                        'dd MMMM yyyy'
                      )
                    : 'not settled'}
                </TokenDetailssValue>
              </TokenDetailsBlock>
            </TokenSaleContainer>
          </TabPanel>
        </StyledProjectDetailsContainer>
        <StyledProjectFinInfoContainer>
          <div style={{ position: 'sticky', top: 0 }}>
            <StyledFinancialInfoContainer>
              <StyledFinancialInfoLine style={{ marginBottom: '10px' }}>
                <StyledFinancialInfoLineLabel>
                  {mockedProject.finInfoLabels.goal}
                </StyledFinancialInfoLineLabel>
                <StyledFinancialInfoLineValue>
                  {project?.poolInformation.toRise}
                </StyledFinancialInfoLineValue>
              </StyledFinancialInfoLine>
              <StyledFinancialInfoLine style={{ marginBottom: '10px' }}>
                <StyledFinancialInfoLineLabel>
                  {mockedProject.finInfoLabels.allocation}
                </StyledFinancialInfoLineLabel>
                <StyledFinancialInfoLineValue>
                  {project?.tokenSaleDetails.allocation
                    ? project?.tokenSaleDetails.allocation
                    : 'TBA'}
                </StyledFinancialInfoLineValue>
              </StyledFinancialInfoLine>
              <StyledFinancialInfoLine style={{ marginBottom: '10px' }}>
                <StyledFinancialInfoLineLabel>
                  {mockedProject.finInfoLabels.pricePerToken}
                </StyledFinancialInfoLineLabel>
                <StyledFinancialInfoLineValue>
                  {project?.tokenSaleDetails.price
                    ? project?.tokenSaleDetails.price
                    : 'TBA'}
                </StyledFinancialInfoLineValue>
              </StyledFinancialInfoLine>
              <StyledFinancialInfoLine style={{ marginBottom: '10px' }}>
                <StyledFinancialInfoLineLabel>
                  {mockedProject.finInfoLabels.saleStart}
                </StyledFinancialInfoLineLabel>
                <StyledFinancialInfoLineValue>
                  {project?.poolInformation.launchDate
                    ? dateFortmatter(
                        project?.poolInformation.launchDate!,
                        'dd MMMM yyyy'
                      )
                    : 'TBA'}
                </StyledFinancialInfoLineValue>
              </StyledFinancialInfoLine>
              <StyledFinancialInfoLine style={{ marginBottom: '50px' }}>
                <StyledFinancialInfoLineLabel>
                  {'Project status'}
                </StyledFinancialInfoLineLabel>
                <StyledFinancialInfoLineValue>
                  {project?.status}
                </StyledFinancialInfoLineValue>
              </StyledFinancialInfoLine>
              <ChangeStatusButton
                disabled={disableButton}
                variant="outlined"
                color="inherit"
                onClick={() => setStatusModal(true)}
              >
                {'Change Status'}
              </ChangeStatusButton>
              <ChangeStatusButton
                disabled={disableButton}
                variant="outlined"
                color="inherit"
                onClick={() => setDeleteModal(true)}
              >
                {'Delete Project'}
              </ChangeStatusButton>
            </StyledFinancialInfoContainer>
          </div>
        </StyledProjectFinInfoContainer>
      </StyledProjectPageWrapper>
      <ChangeStatusModal
        id={id || ''}
        status={project?.status || 'pending'}
        statusModal={statusModal}
        handleStatusModalClose={handleStatusModalClose}
      />
      <DeleteModal
        id={id || ''}
        projectName={project!.projectTitle}
        deleteModal={deleteModal}
        handleDeleteModalClose={handleDeleteModalClose}
      />
    </>
  );
};

export default ProjectPage;
