import { createSlice } from '@reduxjs/toolkit';
import { IStoreStatuses, STATUS_ENUM } from '../../types/statuses';
import { StatusGenerator } from '../../utils/StatusGenerator';
import {
  deleteProjectByIdThunk,
  getAllProjectsThunk,
  getProjectByIdThunk,
  updateProjectByIdThunk,
} from './thunks';
import { IProjects } from '../../types/project';
import { IPaginate } from '../../types/pagination';

interface IState {
  projects: IPaginate | null;
  selectedProject: IProjects | null;
  statuses: IStoreStatuses;
  modalCollections: boolean;
  formCleaner: boolean;
}

const initialState: IState = {
  projects: null,
  selectedProject: null,
  statuses: StatusGenerator.generateStatuses([
    getAllProjectsThunk.typePrefix,
    getProjectByIdThunk.typePrefix,
    deleteProjectByIdThunk.typePrefix,
  ]),
  modalCollections: false,
  formCleaner: false,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload.data ?? null;
    },
    setprojects: (state, action) => {
      state.projects = action.payload.data ?? null;
    },
    modalCollections: (state, action) => {
      state.modalCollections = action.payload ?? false;
    },
    formCleaner: (state, action) => {
      state.formCleaner = action.payload ?? false;
    },
    resetStatus: (state) => {
      state.statuses = initialState.statuses;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllProjectsThunk.pending, (state) => {
        state.statuses[getAllProjectsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getAllProjectsThunk.rejected, (state, { error }) => {
        state.statuses[getAllProjectsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getAllProjectsThunk.fulfilled, (state, { payload }) => {
        state.statuses[getAllProjectsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.projects = payload;
      })
      .addCase(getProjectByIdThunk.pending, (state) => {
        state.statuses[getProjectByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getProjectByIdThunk.rejected, (state, { error }) => {
        state.statuses[getProjectByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getProjectByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getProjectByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedProject = payload;
      })
      .addCase(updateProjectByIdThunk.pending, (state) => {
        state.statuses[getProjectByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(updateProjectByIdThunk.rejected, (state, { error }) => {
        state.statuses[getProjectByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(updateProjectByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getProjectByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedProject = payload;
      })
      .addCase(deleteProjectByIdThunk.pending, (state) => {
        state.statuses[deleteProjectByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(deleteProjectByIdThunk.rejected, (state, { error }) => {
        state.statuses[deleteProjectByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(deleteProjectByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[deleteProjectByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      }),
});

export default projectsSlice;
