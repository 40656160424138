import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config/api';
import { API_ROUTES } from '../../config/apiRoutes';
import { IAdminChangePassword } from '../../types/auth';
import { IPaginate } from '../../types/pagination';
import { INftsListQueryData, INft, GetNftsQuery } from '../../types/nft';

/**
 * @description Dispatch this thunk to get list of NFT
 * @param {INftsListQueryData} queryData - Object containing limit and page (used for pagination)
 */
export const getAllNftsThunk = createAsyncThunk<IPaginate<INft>, GetNftsQuery>(
  'nfts/getAllNftsThunk',
  async (queryData: INftsListQueryData | undefined, { rejectWithValue }) => {
    const { limit = 30, page = 1 } = queryData ?? {};
    try {
      const { data } = await API.get(API_ROUTES.NFT.GET_LIST(limit, page));
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
/**
 * @description Dispatch this thunk to get NFT by id
 * @param {string} id - String id to find current NFT
 */
export const getNftByIdThunk = createAsyncThunk(
  'nfts/getNftByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.NFT.GET_BY_ID(id));
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/**
 * @description Dispatch this thunk to delete NFT by id
 * @param {string} id - String id to find current NFT
 */
export const deleteNftByIdThunk = createAsyncThunk(
  'nfts/deleteNftByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(API_ROUTES.NFT.DELETE_BY_ID(id));
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
