import { createSelector } from 'reselect';
import { RootState } from '..';

const tokensState = (state: RootState) => state.tokens;

/**
 * @description Get list of nft collections available on current page (pagination is used)
 */
export const tokensSelector = createSelector(
  tokensState,
  ({ tokens }) => tokens
);

/**
 * @description Get data of selected nft collection
 */
export const selectedtokenSelector = createSelector(
  tokensState,
  ({ selectedToken }) => selectedToken
);
export const modaltokenSelector = createSelector(
  tokensState,
  ({ modalTokens }) => modalTokens
);
export const formCleanerSelector = createSelector(
  tokensState,
  ({ formCleaner }) => formCleaner
);
export const tokenStatusSelector = createSelector(
  tokensState,
  ({ statuses }) => statuses
);
