import { BigNumber } from 'ethers';

export type QueryProps = {
  newFee: BigNumber;
};

export type Props = {
  feeModal: boolean;
  handleFeeModalClose: () => void;
  handleFeeChange: () => void;
};

export type FeeProps = {
  feeModal: boolean;
  feeAmount: string | number;
  handleFeeModalClose: () => void;
  handleFeeChange: () => void;
};
export const initialValues = {
  currentFee: '',
  newFee: '',
  confirmNewFee: '',
};
