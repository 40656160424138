/**
 * @description Object containing all request endpoints
 */
export const API_ROUTES = {
  AUTH: {
    LOGIN: 'auth/login/',
    SIGN_UP: 'auth/admin-register/',
    CHANGE_PASSWORD: 'auth/change-password/',
    CHANGE_PASSWORD_BY_ADMIN: `auth/change-password-by-admin/`,
    RESET_PASSWORD: 'auth/reset-password/',
  },
  USERS: {
    CREATE: 'users/create/',
    CREATE_MODERATOR: 'users/moderator-create/',
    GET_ADMIN: 'users/admin/',
    GET_LIST: (limit: number, page: number) =>
      `users/?page=${page}&limit=${limit}`,
    DELETE: (id: string) => `users/delete/${id}/`,
    GET_BY_ID: (id: string) => `users/${id}/`,
    UPDATE: (id: string) => `users/${id}/`,
  },
  TOKENS: {
    GET_LIST: (limit: number, page: number, name?: string) =>
      `tokens/?page=${page}&limit=${limit}&name=${name}`,
    GET_BY_ADDRESS: (address: string) => `token/${address}/`,
    UPDATE: (id: string) => `tokens/update/${id}`,
    DELETE_BY_ID: (id: string) => `tokens/delete/${id}/`,
  },
  NFT_COLLECTIONS: {
    GET_LIST: (limit: number, page: number, name?: string) =>
      `nft-collections/all/?page=${page}&limit=${limit}&name=${name}`,
    GET_LIST_FOR_USER: (id: string, limit: number, page: number) =>
      `nft-collection/${id}/?page=${page}&limit=${limit}`,
    GET_BY_ID: (id: string) => `nft-collection/one/${id}/`,
    UPDATE: (id: string) => `nft-collection/update/${id}`,
    DELETE_BY_ID: (id: string) => `nft-collection/delete/${id}/`,
  },
  PROJECTS: {
    GET_LIST: (limit: number, page: number, name?: string) =>
      `projects/admin/?page=${page}&limit=${limit}&name=${name}`,
    GET_LIST_FOR_USER: (id: string, limit: number, page: number) =>
      `project/${id}/?page=${page}&limit=${limit}`,
    GET_BY_ID: (id: string) => `project/one/${id}/`,
    UPDATE: (id: string) => `project/update/${id}`,
    CHANGE_STATUS: (id: string) => `project/status/${id}`,
    DELETE_BY_ID: (id: string) => `project/delete/${id}/`,
  },
  NFT: {
    GET_LIST: (limit: number, page: number, name?: string) =>
      `nfts/all/?page=${page}&limit=${limit}&name=${name}`,
    GET_LIST_FOR_USER: (id: string, limit: number, page: number) =>
      `nft/${id}/?page=${page}&limit=${limit}`,
    GET_BY_ID: (id: string) => `nft/one/${id}/`,
    DELETE_BY_ID: (id: string) => `nft/delete/${id}/`,
  },
  POSTS: {
    CREATE: 'posts/create/',
    DELETE: (id: string) => `posts/delete/${id}/`,
    ALL_POSTS: (limit: number = 8, page: number = 1, language = '') =>
      `posts-admin/?page=${page}&limit=${limit}&language=${language}`,
    GET_BY_ID: (id: string) => `post/${id}/`,
    UPDATE_POST: (id: string) => `posts/update/${id}/`,
    POSTS_URL: () => `posts/blog/url/`,
  },
  ONE_TIME_LINKS: {
    GENERATE: 'one-time-link/generate/',
    VALIDATE: 'one-time-link/validate/',
    DELETE: (token: string) => `one-time-link/delete/${token}`,
  },
};
