import { DockSharp } from '@mui/icons-material';
import { createSelector } from 'reselect';
import { RootState } from '..';

const projectsState = (state: RootState) => state.projects;

/**
 * @description Get list of projects available on current page (pagination is used)
 */
export const projectsSelector = createSelector(
  projectsState,
  ({ projects }) => projects
);

/**
 * @description Get data of selected project
 */
export const selectedProjectSelector = createSelector(
  projectsState,
  ({ selectedProject }) => selectedProject
);
export const modalProjectSelector = createSelector(
  projectsState,
  ({ modalCollections }) => modalCollections
);
export const formCleanerSelector = createSelector(
  projectsState,
  ({ formCleaner }) => formCleaner
);
export const projectStatusSelector = createSelector(
  projectsState,
  ({ statuses }) => statuses
);
