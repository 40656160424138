import { Button, Popper, styled, Typography, Box, List } from '@mui/material';
import {
  Border,
  BorderRadius,
  BoxShadow,
  Color,
  FontFamily,
  TextSize,
} from '../../helpers/themeStyles';

export const AppBarButton = styled(Button)(({ theme }) => ({
  background: Color.BORDER_WHITE_EXTRA_OPACITY,
  textTransform: 'capitalize',
  color: Color.WHITE,
  justifyContent: 'space-between',
  padding: '8px 14px 8px 8px',
  minWidth: '135px',
  height: '40px',
  borderRadius: BorderRadius.HUGE,
  border: `1px solid ${Color.WHITE_OPACITY}`,
  '&.MuiButtonBase-root': {
    justifyContent: 'center',
    gap: '8px',
  },

  [theme.breakpoints.up('mobile')]: {
    '&:hover': {
      background: Color.BORDER_WHITE_EXTRA_OPACITY,
    },
  },
  [theme.breakpoints.down('mobile')]: {
    minWidth: '66px',
  },
}));

export const NetworkName = styled(Typography)({
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  fontFamily: FontFamily.INTER,
});
export const NetworkIcon = styled('img')({
  width: '24px',
  height: '24px',
  borderRadius: BorderRadius.HUGE,
});
export const NetworkPopper = styled(Popper)({
  paddingTop: '15px',
  zIndex: 1500,
});
export const NetworkBox = styled(Box)({
  minWidth: '200px',
  padding: '16px',
  boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
  borderRadius: BorderRadius.SMALL,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  color: Color.WHITE,
});
export const NetworkList = styled(List)({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '& .MuiTypography-root': {
    fontFamily: FontFamily.INTER,
  },
});
