import axios from 'axios';
import { useEffect } from 'react';
import { useAccount, useDisconnect, useSigner } from 'wagmi';
import WelcomeIcon from '../../shared/WelcomeIcon.svg';
import {
  StyledDialoig,
  StyledTitle,
  StyledDexIcon,
  StyledPrivacyPolicy,
  StyledContainer,
  StyledSignBtn,
  StyledCancelBtn,
} from './styles';
import { API } from '../../config/api';

const WelcomeNftPopup = ({
  isOpen,
  onClose,
  isConnecting,
}: {
  isOpen: boolean;
  onClose: () => void;
  isConnecting?: boolean;
}) => {
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const { disconnect } = useDisconnect();
  useEffect(() => {}, [signer]);
  async function getNonce() {
    try {
      const data = await API.get(
        `https://game-dex-api.omisoft.net/auth/nonce?publicAddress=${address}`
      );

      const signature = await signer!.signMessage(data.data.user.nonce);
      const tokens = await axios.post(
        `https://game-dex-api.omisoft.net/auth/crypto-login`,
        {
          signature: signature,
          nonce: data.data.user.nonce,
        }
      );
      localStorage.setItem('accessToken', tokens.data.accessToken);
      localStorage.setItem('refreshToken', tokens.data.refreshToken);
      localStorage.setItem('userId', tokens.data.user._id);
      onClose();
      window.location.reload();
    } catch (error) {
      disconnect();
      onClose();
    }
  }
  useEffect(() => {}, [signer]);

  return (
    <StyledDialoig open={isOpen}>
      <StyledContainer>
        <StyledDexIcon src={WelcomeIcon} />
        <StyledTitle>Welcome to Planetex DeFi</StyledTitle>

        <StyledSignBtn
          onClick={() => {
            getNonce();
          }}
        >
          Accept and sign
        </StyledSignBtn>
        <StyledCancelBtn
          onClick={() => {
            disconnect();
            onClose();
          }}
        >
          cancel
        </StyledCancelBtn>
      </StyledContainer>
    </StyledDialoig>
  );
};
export default WelcomeNftPopup;
