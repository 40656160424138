import { DeleteModalProps } from './config';
import { Modal, IconButton, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Color, TextSize } from '../../helpers/themeStyles';
import {
  StyledModalContent,
  ContentWrap,
  StyledModalTitle,
  StyledConnectItem,
  ConnectItemWrap,
  StyledModalFooter,
  StyledResultlTitle,
  StyledDeleteItem,
  DeleteItemWrap,
} from './styles';

import { deleteProjectByIdThunk } from '../../redux/projects/thunks';
import { AdminRoutes } from '../../config/routes';

const DeleteModal = (props: DeleteModalProps) => {
  const dispatch = useDispatch();
  const handleDeleteProject = () => {
    dispatch(deleteProjectByIdThunk(props.id));
    props.handleDeleteModalClose();
  };

  return (
    <>
      <Modal
        open={props.deleteModal}
        onClose={props.handleDeleteModalClose}
      >
        <StyledModalContent>
          <ContentWrap>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                padding: '16px',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
              }}
            >
              <StyledModalTitle style={{ fontSize: TextSize.MIDDLE }}>
                Are you shure, you want to delete project{' '}
                {props.projectName.toUpperCase()}?
              </StyledModalTitle>
              <IconButton
                sx={{ p: 0, color: Color.WHITE }}
                onClick={props.handleDeleteModalClose}
              >
                <Close />
              </IconButton>
            </Stack>
            <Stack
              style={{ padding: '16px' }}
              gap={1.5}
            >
              <StyledDeleteItem
                to={AdminRoutes.Projects}
                onClick={() => handleDeleteProject()}
              >
                <DeleteItemWrap>
                  <StyledResultlTitle>Yes</StyledResultlTitle>
                </DeleteItemWrap>
              </StyledDeleteItem>
              <StyledConnectItem onClick={() => props.handleDeleteModalClose()}>
                <ConnectItemWrap>
                  <StyledResultlTitle>No</StyledResultlTitle>
                </ConnectItemWrap>
              </StyledConnectItem>
            </Stack>
            <StyledModalFooter></StyledModalFooter>
          </ContentWrap>
        </StyledModalContent>
      </Modal>
    </>
  );
};

export default DeleteModal;
