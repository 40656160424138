import * as React from 'react';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import { Cancel } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Grid,
  Typography,
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormHelperText,
} from '@mui/material';
import 'react-markdown-editor-lite/lib/index.css';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import {
  initialValues,
  initialValuesI,
  initialValuesEdit,
  initialValuesEditI,
} from './config';
import {
  selectedPostSelector,
  loadingSelector,
  allPostsUrlsSelector,
  postStatusSelector,
} from '../../redux/posts/selectors';
import {
  TOPIC_ACTIONS,
  BTN_TITLE,
  INPUT_LABEL,
  STATUS_TYPES_ENUM,
  STATUS_SELECT,
  LoadingResultsT,
  LANGUAGES_TYPES_ENUM,
} from '../../helpers/constants';
import {
  editPostThunk,
  createPostThunk,
  getPostByIdThunk,
  getAllUrlThunk,
} from '../../redux/posts/thunks';
import postSlice from '../../redux/posts/slice';
import AdminMainButton from '../../components/AdminMainButton';
import CancelButton from '../../components/CancelButton';
import Container from '../../components/Container';
import Notification from '../../utils/Notification';
import { AdminRoutes } from '../../config/routes';
import {
  StyledGrid,
  ButtonsWrapper,
  SaveButtonWrapper,
  StyledTitle,
  TextFieldWrapper,
  StyledGridBottom,
  StyledGridItem,
  InputGrid,
  StyledGridSelect,
  StyledTitleWrapper,
  FormWrapper,
  StyledTitleGrid,
  FlagImg,
} from './styles';
import { getProjectByIdThunk } from '../../redux/projects/thunks';
import { selectedProjectSelector } from '../../redux/projects/selectors';
import ProjectPage from '../../components/ProjectPage';

const Topic: React.FC = () => {
  const allUrls = useSelector(allPostsUrlsSelector);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectedProjectSelector);
  const mdParser = new MarkdownIt();
  const { id } = params;
  const actionTitle = 'Project Details';
  const [tags, setTags] = useState<string[]>([]);
  const tagRef = useRef<HTMLInputElement>();
  const [notificationIsOpen, setNotification] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(),
    enableReinitialize: true,
    onSubmit: (values, helpers) => {
      handleSubmit(values);
      dispatch(postSlice.actions.resetSelectedPost());
    },
  });

  React.useEffect(() => {
    dispatch(getProjectByIdThunk(id || ''));
  }, [dispatch, id]);

  const handleChangeMarkdown = (text: string) =>
    formik.setFieldValue('topic', text);

  const hadleCloseNotification = () => {
    setNotification(false);
    dispatch(postSlice.actions.resetStatus());
  };

  const handleOpenNotification = () => setNotification(true);

  const handleSubmit = (values: initialValuesI | initialValuesEditI) => {
    values.tags = tags;
    handleOpenNotification();

    values.status = STATUS_TYPES_ENUM.PENDING;
    setTags([]);
  };

  const cancelHandler = () => {
    setTags([]);
    navigate(AdminRoutes.Topics);
    dispatch(postSlice.actions.resetSelectedPost());
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 600,
        width: 430,
      },
    },
  };
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
  };

  return (
    <Container>
      <StyledTitleWrapper>
        <StyledTitle>{actionTitle}</StyledTitle>
      </StyledTitleWrapper>

      {project ? <ProjectPage /> : <h1>loading</h1>}
    </Container>
  );
};

export default Topic;
