import React from 'react';

const TwitterIcon = () => {
  return (
    <svg
      width='20'
      height='16'
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 -0.000976562C19.22 0.462023 17.655 1.09302 16.735 1.27502C16.708 1.28202 16.686 1.29102 16.66 1.29802C15.847 0.496023 14.733 -0.000976562 13.5 -0.000976562C11.015 -0.000976562 9 2.01402 9 4.49902C9 4.63002 8.989 4.87102 9 4.99902C5.647 4.99902 3.095 3.24302 1.265 0.999023C1.066 1.49902 0.979 2.28902 0.979 3.03102C0.979 4.43202 2.074 5.80802 3.779 6.66102C3.465 6.74202 3.119 6.80002 2.759 6.80002C2.178 6.80002 1.563 6.64702 1 6.18302C1 6.20002 1 6.21602 1 6.23402C1 8.19202 3.078 9.52502 4.926 9.89602C4.551 10.117 3.795 10.139 3.426 10.139C3.166 10.139 2.246 10.02 2 9.97402C2.514 11.579 4.368 12.481 6.135 12.513C4.753 13.597 3.794 13.999 0.964 13.999H0C1.788 15.145 4.065 16 6.347 16C13.777 16 18 10.337 18 4.99902C18 4.91302 17.998 4.73302 17.995 4.55202C17.995 4.53402 18 4.51702 18 4.49902C18 4.47202 17.992 4.44602 17.992 4.41902C17.989 4.28302 17.986 4.15602 17.983 4.09002C18.773 3.52002 19.458 2.80902 20 1.99902C19.275 2.32102 18.497 2.53702 17.68 2.63502C18.514 2.13502 19.699 0.943023 20 -0.000976562Z'
        fill='white'
      />
    </svg>
  );
};

export default TwitterIcon;
