/**
 * @description Enum containing routes to all pages rendered by react-router
 */
export enum AdminRoutes {
  Login = '/',
  Users = '/users',
  SignUp = '/signup',
  ResetPassword = '/reset-password/:token',
  Settings = '/settings',
  Collections = '/collections',
  Topics = '/topics',
  NftCollections = '/nft-collections',
  Projects = '/projects',
  Nfts = '/nfts',
  Topic = '/topic',
  Tokens = '/tokens',
  Project = '/project',
}
