import { createSlice } from '@reduxjs/toolkit';

type swapSettings = {
  slippage: number;
  deadline: number;
};

interface IState {
  swapSettings: swapSettings;
  openTopSwitchPanel: Boolean;
}

const initialState: IState = {
  swapSettings: {
    slippage: 0.5,
    deadline: Math.floor(Date.now() / 1000) + 60 * 30,
  },
  openTopSwitchPanel: true,
};

const tokenSlice = createSlice({
  name: 'token',
  initialState: initialState,
  reducers: {
    setSwapSettings: (state, action) => {
      state.swapSettings = {
        deadline:
          Math.floor(Date.now() / 1000) + 60 * Number(action.payload.deadline),
        slippage: action.payload.slippage,
      };
    },
    openTopSwitchPanel: (state, action) => {
      state.openTopSwitchPanel = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { setSwapSettings, openTopSwitchPanel } = tokenSlice.actions;

export default tokenSlice;
