import { useState } from 'react';
import { StatusProps } from './config';
import { Modal, IconButton, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Color, TextSize } from '../../helpers/themeStyles';
import {
  StyledModalContent,
  ContentWrap,
  StyledModalTitle,
  StyledConnectItem,
  ConnectItemWrap,
  StyledModalFooter,
  StyledStatustItem,
  StyledResultlTitle,
  ChangeStatusApprove,
} from './styles';

import { updateProjectSTatusByIdThunk } from '../../redux/projects/thunks';
import { STATUS_SELECT, STATUS_TYPES_ENUM } from '../../helpers/constants';

const ChangeStatusModal = (props: StatusProps) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');
  const [buttonStatus, setButtonStatus] = useState('..');
  const handleChangeProject = (status: string) => {
    dispatch(updateProjectSTatusByIdThunk({ _id: props.id, status }));
    props.handleStatusModalClose();
  };
  const handleChangeStatus = (status: string) => {
    setButtonStatus(status);
    setStatus(status);
  };

  return (
    <>
      <Modal
        open={props.statusModal}
        onClose={props.handleStatusModalClose}
      >
        <StyledModalContent>
          <ContentWrap>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                padding: '16px',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
              }}
            >
              <StyledModalTitle style={{ fontSize: TextSize.MIDDLE }}>
                Change project Status
              </StyledModalTitle>
              <IconButton
                sx={{ p: 0, color: Color.WHITE }}
                onClick={props.handleStatusModalClose}
              >
                <Close />
              </IconButton>
            </Stack>
            <Stack
              style={{ padding: '16px' }}
              gap={1.5}
            >
              <StyledResultlTitle>
                Change project status on {buttonStatus.toUpperCase()}?
              </StyledResultlTitle>
              <StyledStatustItem
                disabled={props.status === STATUS_TYPES_ENUM.APPROVED}
                onClick={() => handleChangeStatus(STATUS_TYPES_ENUM.APPROVED)}
              >
                <ConnectItemWrap>
                  <StyledModalTitle>{STATUS_SELECT.APPROVED}</StyledModalTitle>
                </ConnectItemWrap>
              </StyledStatustItem>
              <StyledStatustItem
                disabled={props.status === STATUS_TYPES_ENUM.PENDING}
                onClick={() => handleChangeStatus(STATUS_TYPES_ENUM.PENDING)}
              >
                <ConnectItemWrap>
                  <StyledModalTitle>{STATUS_SELECT.PENDING}</StyledModalTitle>
                </ConnectItemWrap>
              </StyledStatustItem>
              <ChangeStatusApprove>
                <StyledConnectItem onClick={() => handleChangeProject(status)}>
                  <ConnectItemWrap>
                    <StyledResultlTitle>OK</StyledResultlTitle>
                  </ConnectItemWrap>
                </StyledConnectItem>
                <StyledConnectItem
                  onClick={() => props.handleStatusModalClose()}
                >
                  <ConnectItemWrap>
                    <StyledResultlTitle>Cansel</StyledResultlTitle>
                  </ConnectItemWrap>
                </StyledConnectItem>
              </ChangeStatusApprove>
            </Stack>
            <StyledModalFooter></StyledModalFooter>
          </ContentWrap>
        </StyledModalContent>
      </Modal>
    </>
  );
};

export default ChangeStatusModal;
