import { styled } from '@mui/material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import {
  BorderRadius,
  Color,
  FontFamily,
  TextSize,
  Border,
} from '../../helpers/themeStyles';
import { Link } from 'react-router-dom';

export const StyledModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 412,
  background: Color.BACKGROUND,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: BorderRadius.SMALL,
  color: Color.WHITE,

  [theme.breakpoints.down('mobile')]: {
    width: '100%',
    top: '57.5%',
    border: 'none',
    borderRadius: '0px',
  },
}));

export const ContentWrap = styled('div')(({ theme }) => ({
  backgroundColor: Color.DARK_PURPLE_OPACITY_NEXT,
  borderRadius: '8px',

  [theme.breakpoints.down('mobile')]: {
    height: '100vh',
    borderRadius: '0px',
    backgroundColor: Color.BACKGROUND_MAIN,
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
  },
}));

export const StyledModalTitle = styled(Typography)(() => ({
  fontSize: TextSize.SMALL,
  fontWeight: 600,
  fontFamily: FontFamily.INTER,
}));
export const StyledResultlTitle = styled(Typography)(() => ({
  fontSize: TextSize.SMALL,
  fontWeight: 600,
  fontFamily: FontFamily.INTER,
}));
export const StyledConnectItem = styled(Button)(() => ({
  marginRight: 15,
  color: Color.WHITE,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: BorderRadius.SMALL,
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  padding: 0,
  width: 30,
}));
export const StyledStatustItem = styled(Button)(() => ({
  color: Color.WHITE,
  border: Border.TRANSPARENT_BORDER,
  backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: BorderRadius.SMALL,
  textTransform: 'none',
  fontFamily: FontFamily.INTER,
  padding: 0,
  width: '200px',
  margin: '0 auto',
}));
export const ConnectItemWrap = styled('div')(() => ({
  backgroundColor: Color.DARK_PURPLE_OPACITY_MIDDLE,
  borderRadius: '8px',
  padding: '16px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

export const StyledAvatar = styled(Avatar)(() => ({
  width: 28,
  height: 28,
  objectFit: 'cover',
  marginRight: '8px',
  borderRadius: '0',
}));

export const StyledModalFooter = styled(Box)(() => ({
  color: Color.WHITE,
  padding: '8px 16px 32px',
  fontFamily: FontFamily.INTER,
}));

export const StyledModalFooterText = styled(Typography)(() => ({
  fontFamily: FontFamily.INTER,
  color: Color.WHITE,
  fontSize: TextSize.SMALL,
  lineHeight: 1.5,
  letterSpacing: 0,
}));

export const StyledModalLink = styled(Link)(() => ({
  textDecoration: 'none',
  color: Color.PURPLE,
}));

export const ChangeStatusApprove = styled('div')(() => ({
  margin: '0 auto',
  marginTop: 30,
}));
