import * as React from 'react';
import { useEffect } from 'react';
import { Checkbox, TextField, Tooltip } from '@mui/material';
import Container from '../../components/Container';
import LongMenu from '../../components/LongMenu/LongMenu';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Pagination } from '@mui/material';
import { format } from 'date-fns';

import {
  deleteProjectByIdThunk,
  getAllProjectsThunk,
  updateProjectByIdThunk,
} from '../../redux/projects/thunks';
import {
  MainWrapper,
  NoTopicsWrapper,
  ImageBackground,
  FotoProject,
  ProjectName,
  AllProjectsWrapper,
  CreateProjectsWrapper,
  TitleProjects,
  ProjectsList,
  ProjectListWrapper,
  StatusProject,
  InfoProject,
  ProjectOwner,
  NoTopicsTitleWrapper,
  StyledTitleNoTopics,
  AvatarWrap,
  CardWrap,
  IconWrap,
  RowWrap,
  SmallText,
  TableItemStyled,
  TableItemWrap,
  TableTitleStyled,
  TableTitleWrap,
  TitleText,
  StyledTDataAction,
} from './styles';
import projectsSlice from '../../redux/projects/slice';
import { projectsSelector } from '../../redux/projects/selectors';
import { userSelector } from '../../redux/auth/selectors';
import { IProjects } from '../../types/project';
import { LinkWrapper, StyledImage } from '../Nfts/styles';
import { formatIpfsUrl } from '../../utils/formatIpfsUrl';
import ActionTopicButton from '../../components/ActionTopicButton';
import { ICON_TITLE, BTN_TITLE } from '../../helpers/constants';
import { AdminRoutes } from '../../config/routes';

const Projects: React.FC = () => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(projectsSelector);
  const authUser: any = useAppSelector(userSelector);
  const isAdmin: boolean = Boolean(authUser?.user?.role);
  const allCollectionsDiv = React.useRef<HTMLDivElement>(null);
  const [collection, setCollection] = React.useState({});
  const [filterText, setfilterText] = React.useState('');

  const [selectedCollectionId, setSelectedCollectionId] =
    React.useState<string>('');
  useEffect(() => {
    dispatch(getAllProjectsThunk({ limit: 100, page: 1, name: filterText }));
  }, [collection, filterText, selectedCollectionId]);

  const handleDelete = async (id: string) => {
    await dispatch(deleteProjectByIdThunk(id));
    setSelectedCollectionId(id);
  };

  const handleSetFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 3) {
      setfilterText(e.target.value);
    }
    if (e.target.value.length === 0) {
      setfilterText(e.target.value);
    }
  };

  const renderProjects = (arr: IProjects[]) => {
    return (
      <CardWrap style={{ borderCollapse: 'collapse' }}>
        <thead>
          <TableTitleWrap>
            <TableTitleStyled>{'Project Logo'}</TableTitleStyled>
            <TableTitleStyled>{'Project Name'}</TableTitleStyled>
            <TableTitleStyled>{'Project owner email'}</TableTitleStyled>
            <TableTitleStyled>{'Project status'}</TableTitleStyled>
            <TableTitleStyled style={{ textAlign: 'center' }}>
              {'creation date'}
            </TableTitleStyled>
            <TableTitleStyled style={{ textAlign: 'center' }}>
              {'launch date'}
            </TableTitleStyled>
          </TableTitleWrap>
        </thead>
        <tbody>
          {arr.map((project: IProjects) => {
            return (
              <TableItemWrap key={project._id}>
                <TableItemStyled>
                  <RowWrap>
                    <AvatarWrap>
                      <StyledImage
                        src={
                          project?.logo[1]
                            ? formatIpfsUrl(project?.logo[1]!)
                            : '/images/mockCoin.png'
                        }
                        alt=""
                      />
                    </AvatarWrap>
                  </RowWrap>
                </TableItemStyled>
                <TableItemStyled>
                  <RowWrap>
                    <div>
                      <TitleText>{project.projectTitle}</TitleText>
                      <SmallText
                        style={{ marginTop: '6px', fontWeight: '700' }}
                      >
                        {project.subtitle}
                      </SmallText>
                    </div>
                  </RowWrap>
                </TableItemStyled>
                <TableItemStyled>
                  <TitleText>{project.contactEmail}</TitleText>
                </TableItemStyled>
                <TableItemStyled>
                  <TitleText>{project.status}</TitleText>
                </TableItemStyled>
                <TableItemStyled style={{ textAlign: 'center' }}>
                  <TitleText style={{ marginBottom: '6px' }}>
                    {format(new Date(`${project.createdAt}`), 'dd.MM.yyyy')}
                  </TitleText>
                </TableItemStyled>
                <TableItemStyled style={{ textAlign: 'center' }}>
                  <TitleText style={{ marginBottom: '6px' }}>
                    {project.poolInformation.launchDate
                      ? format(
                          new Date(`${project.poolInformation.launchDate}`),
                          'dd.MM.yyyy'
                        )
                      : 'not settled'}
                  </TitleText>
                </TableItemStyled>
                <TableItemStyled>
                  <StatusProject>
                    {/* <TableItemStyled>
                      <StyledTDataAction>
                        <LinkWrapper
                          to={``}
                        >
                          <Tooltip
                            title={ICON_TITLE.SHOW_DETAILS}
                            arrow
                          >
                            <ActionTopicButton
                              onClick={() => handleDelete(project._id)}
                              text={BTN_TITLE.DELETE}
                            />
                          </Tooltip>
                        </LinkWrapper>
                      </StyledTDataAction>
                    </TableItemStyled> */}
                    <TableItemStyled>
                      <StyledTDataAction>
                        <LinkWrapper
                          to={`${AdminRoutes.Project}/${project._id}`}
                        >
                          <Tooltip
                            title={ICON_TITLE.SHOW_DETAILS}
                            arrow
                          >
                            <ActionTopicButton text={BTN_TITLE.SHOW} />
                          </Tooltip>
                        </LinkWrapper>
                      </StyledTDataAction>
                    </TableItemStyled>
                  </StatusProject>
                </TableItemStyled>
              </TableItemWrap>
            );
          })}
        </tbody>
      </CardWrap>
    );
  };

  return (
    <Container>
      <MainWrapper>
        <AllProjectsWrapper ref={allCollectionsDiv}>
          <TitleProjects>All Projects</TitleProjects>
          {/* <TextField
            label="Filter By Project's Name"
            variant="filled"
            InputLabelProps={{ style: { fontWeight: 400, color: 'white' } }}
            sx={{
              color: 'white',
              width: 300,
              marginBottom: 7,
              fontWeight: 900,
              '& .Mui-focused': {
                backgroundColor: 'transparent',
                color: 'white',
              },
            }}
            onChange={handleSetFilter}
          /> */}
          {projects?.docs.length ? (
            <>
              <ProjectsList>{renderProjects(projects?.docs)}</ProjectsList>
              <Pagination
                hideNextButton
                hidePrevButton
                defaultPage={projects?.page}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '20px',
                  'li button': {
                    fontFamily: `"Montserrat", sans-serif`,
                    fontWeight: 600,
                    color: '#717384',
                  },
                }}
                page={projects?.page}
                count={projects?.totalPages}
                onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                  dispatch(
                    getAllProjectsThunk({
                      limit: projects?.limit,
                      page: page,
                    })
                  );
                }}
              />
            </>
          ) : (
            <NoTopicsWrapper>
              <ImageBackground></ImageBackground>
              <NoTopicsTitleWrapper>
                <StyledTitleNoTopics>Have no Projects</StyledTitleNoTopics>
              </NoTopicsTitleWrapper>
            </NoTopicsWrapper>
          )}
        </AllProjectsWrapper>
      </MainWrapper>
    </Container>
  );
};

export default Projects;
