import { useLocation } from 'react-router-dom';
import {
  StyledContainer,
  StyledAppVersion,
  LinkWrapper,
  StyledLink,
  StyledButton,
  StyledMenuImg,
  StyledMenImg,
  ButtonWrapper,
  ButtonImgWrapper,
  ButtonTextWrapper,
  StyledLogo,
  StyledMenuCollectionImg,
  LogoWrap,
} from './styles';
import { AdminRoutes } from '../../config/routes';
import { TOPICS_BUTTON } from '../../helpers/constants';
import LogoFullIcon from '../../svg/LogoFullIcon';
export default function SideBar() {
  const location = useLocation();
  const isNfts = location.pathname === TOPICS_BUTTON.NFTS;
  const isCollections = location.pathname === TOPICS_BUTTON.COLLECTIONS;
  const isTokens = location.pathname === TOPICS_BUTTON.TOKENS;
  const isProjects = location.pathname === TOPICS_BUTTON.PROJECTS;

  return (
    <StyledContainer>
      <LinkWrapper>
        <StyledLink to="/">
          <LogoWrap>
            {' '}
            <LogoFullIcon />{' '}
          </LogoWrap>
        </StyledLink>
        <ButtonWrapper>
          <StyledLink to={AdminRoutes.Nfts}>
            <StyledButton
              style={
                isNfts
                  ? {
                      background:
                        'linear-gradient(90deg, #E15096 0%, #AE2CB1 53.12%, #4132CF 100%)',
                    }
                  : { background: 'inherit' }
              }
            >
              <ButtonImgWrapper>
                <StyledMenuImg
                  src="/images/menu.svg"
                  alt="menu"
                />
              </ButtonImgWrapper>
              <ButtonTextWrapper>Nfts</ButtonTextWrapper>
            </StyledButton>
          </StyledLink>
          <StyledLink to={AdminRoutes.Tokens}>
            <StyledButton
              style={
                isTokens
                  ? {
                      background:
                        'linear-gradient(90deg, #E15096 0%, #AE2CB1 53.12%, #4132CF 100%)',
                    }
                  : { background: 'inherit' }
              }
            >
              <ButtonImgWrapper>
                <StyledMenImg
                  src="/images/coins.svg"
                  alt="coins"
                />
              </ButtonImgWrapper>
              <ButtonTextWrapper>Tokens</ButtonTextWrapper>
            </StyledButton>
          </StyledLink>
          <StyledLink to={AdminRoutes.NftCollections}>
            <StyledButton
              style={
                isCollections
                  ? {
                      background:
                        'linear-gradient(90deg, #E15096 0%, #AE2CB1 53.12%, #4132CF 100%)',
                    }
                  : { background: 'inherit' }
              }
            >
              <ButtonImgWrapper>
                <StyledMenuCollectionImg
                  src="/images/collections.svg"
                  alt="nft"
                />
              </ButtonImgWrapper>
              <ButtonTextWrapper>NFT Collections</ButtonTextWrapper>
            </StyledButton>
          </StyledLink>
          <StyledLink to={AdminRoutes.Projects}>
            <StyledButton
              style={
                isProjects
                  ? {
                      background:
                        'linear-gradient(90deg, #E15096 0%, #AE2CB1 53.12%, #4132CF 100%)',
                    }
                  : { background: 'inherit' }
              }
            >
              <ButtonImgWrapper>
                <StyledMenuCollectionImg
                  src="/images/project.svg"
                  alt="nft"
                />
              </ButtonImgWrapper>
              <ButtonTextWrapper>Projects</ButtonTextWrapper>
            </StyledButton>
          </StyledLink>
        </ButtonWrapper>
      </LinkWrapper>

      <StyledAppVersion>v1.0.0</StyledAppVersion>
    </StyledContainer>
  );
}
