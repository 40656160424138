import { ethers } from 'ethers';
import { BigNumber } from 'ethers/lib/ethers';
import { useContractWrite, usePrepareContractWrite } from 'wagmi';
import { abi } from '../utils/abi';
import { NFT_MARKETPLACE } from './constants';

export const ContractWriteFees = (chainId: number = 5, newValue: BigNumber) => {
  const contractAddress = NFT_MARKETPLACE[chainId]?.market;
  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: abi.abi,
    functionName: 'setPlatformFee',
    args: [newValue],
  });
  //@ts-ignore
  const { data, isLoading, isSuccess, write } = useContractWrite(config);
  return { data, isLoading, isSuccess, write };
};
