import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAppSelector } from '../hooks/useAppSelector';
import { authStatusSelector } from '../redux/auth/selectors';
import { userStatusSelector } from '../redux/users/selectors';
import { postStatusSelector } from '../redux/posts/selectors';
import { Messages } from '../helpers/constants';

type NotificationProps = {
  isOpen?: boolean;
  onClose: () => void;
  isFeeChanged?: boolean;
};

export const NotificationMessage = () => {
  const authStatuses = useAppSelector(authStatusSelector);
  const postStatuses = useAppSelector(postStatusSelector);
  const userStatuses = useAppSelector(userStatusSelector);
  return {
    errorMessage:
      authStatuses['auth/resetPasswordThunk']?.error ||
      authStatuses['auth/changePasswordThunk']?.error ||
      userStatuses['users/changePasswordByAdminThunk']?.error,
    successMessage:
      authStatuses['auth/resetPasswordThunk']?.success ||
      authStatuses['auth/changePasswordThunk']?.success ||
      userStatuses['users/changePasswordByAdminThunk']?.success,
  };
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
    />
  );
});

function Notification({ isOpen, onClose, isFeeChanged }: NotificationProps) {
  const authStatuses = useAppSelector(authStatusSelector);
  const userStatuses = useAppSelector(userStatusSelector);
  const errorMessage =
    authStatuses['auth/resetPasswordThunk']?.error ||
    authStatuses['auth/changePasswordThunk']?.error ||
    userStatuses['auth/changePasswordByAdminThunk']?.error;

  let successMessage;

  if (authStatuses['auth/resetPasswordThunk']?.success) {
    successMessage = Messages.successPassword;
  }
  if (authStatuses['auth/changePasswordThunk']?.success) {
    successMessage = Messages.successPassword;
  }
  if (userStatuses['auth/changePasswordByAdminThunk']?.success) {
    successMessage = Messages.successPassword;
  }
  if (isFeeChanged) {
    successMessage = Messages.needTime;
  }

  const severity = errorMessage ? 'error' : 'success';
  const message = errorMessage || successMessage;

  return message ? (
    <Snackbar
      open={isOpen || isFeeChanged}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  ) : null;
}

export default Notification;
