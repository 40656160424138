import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import Notification from '../../utils/Notification';
import MenuListComposition from '../AdminControlList/AdminControlList';
import { modalUserSelector } from '../../redux/users/selectors';
import {
  StyledWrapper,
  StyledContainer,
  MainContainer,
  HeaderContainer,
  Title,
  ButtonBlock,
} from './styles';

import SideBar from '../SideBar';
import ResetPasswordModal from '../ResetPasswordModal/ResetPasswordModal';
import { changePasswordThunk } from '../../redux/auth/thunks';
import { IChangePassword } from '../../types/auth';
import { TOPICS_BUTTON, TOPIC_ACTIONS } from '../../helpers/constants';
import authSlice from '../../redux/auth/slice';
import { AdminRoutes } from '../../config/routes';
import postSlice from '../../redux/posts/slice';
import { useAppSelector } from '../../hooks/useAppSelector';
import ResetFeeModal from '../ResetFeeModal/ResetFeeModal';
import { useAccount } from 'wagmi';
import ConnectWalletModal from '../ConnectWalletModal/ConnectWalletModal';
import NetworkSelector from '../NetworkSelector';
import { NFT_MARKETPLACE } from '../../helpers/constants';
import { BigNumber } from 'ethers';
import { useContractRead, useNetwork } from 'wagmi';
import { abi } from '../../utils/abi';

const Container: React.FC = ({ children }) => {
  const { address, isConnected } = useAccount();
  const [walletConnected, setwalletConnected] = useState(isConnected);
  type FeeResult = {
    data: BigNumber | undefined;
    isError: Boolean;
  };
  const { chain } = useNetwork();
  const chainId = chain?.id || 5;
  const contractAddress = NFT_MARKETPLACE[chainId]?.market;
  const { data: feePercentage, isError }: FeeResult = useContractRead({
    address: contractAddress,
    abi: abi.abi,
    functionName: 'bips',
  });

  const feeAmount = feePercentage ? feePercentage.toNumber() / 100 : '-';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isUSers = location.pathname === TOPICS_BUTTON.USERS;
  const isTokens = location.pathname === TOPICS_BUTTON.TOKENS;
  const isCollection = location.pathname === TOPICS_BUTTON.COLLECTIONS;
  const isNfts = location.pathname === TOPICS_BUTTON.NFTS;
  const modal = useAppSelector(modalUserSelector);
  const headerTitle = isUSers
    ? TOPIC_ACTIONS.USERS
    : isCollection
    ? TOPIC_ACTIONS.NFT_COLLECTIONS
    : isTokens
    ? TOPIC_ACTIONS.TOKENS
    : isNfts
    ? TOPIC_ACTIONS.NFT
    : TOPIC_ACTIONS.PROJECT;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [notificationIsOpen, setNotification] = useState(false);
  const [isFeeChanged, setisFeeChanged] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [feeModal, setFeeModal] = useState(false);
  const [walletModal, setWalletModal] = useState(false);

  const handleMenuClose = (e: React.MouseEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    if (
      element.nodeName === 'LI' ||
      element.nodeName === 'svg' ||
      element.nodeName === 'P' ||
      element.nodeName === 'path'
    ) {
      return;
    }
    setMenuIsOpen(false);
  };
  const handleConnectWalletChange = () => {
    setNotification(true);
  };
  React.useEffect(() => {
    setWalletModal(walletModal);
  }, [isConnected]);

  const handleWalletModalClose = () => {
    setWalletModal(false);
  };

  const hadleCloseNotification = () => {
    setisFeeChanged(false);
    setNotification(false);
    dispatch(authSlice.actions.resetStatus());
    dispatch(postSlice.actions.resetStatus());
  };
  const handlePasswordModalClose = () => {
    setPasswordModal(false);
  };

  const handlePasswordModalOpen = () => {
    setPasswordModal(true);
  };

  const handleFeeModalOpen = () => {
    setFeeModal(true);
  };
  const handleConnectWalletModalOpen = () => {
    setWalletModal(true);
  };
  const handleFeeModalClose = () => {
    setFeeModal(false);
  };

  const handlePasswordChange = (values: IChangePassword) => {
    dispatch(changePasswordThunk(values));
    setNotification(true);
  };
  const handleFeeChange = () => {
    setNotification(true);
    setisFeeChanged(true);
  };
  const handleLogout = async () => {
    localStorage.clear();
    dispatch(authSlice.actions.reset());
    window.location.reload();
  };

  return (
    <MainContainer onClick={handleMenuClose}>
      <SideBar />
      <StyledWrapper>
        <HeaderContainer>
          <Title>{headerTitle}</Title>
          <ButtonBlock>
            <NetworkSelector isConnected={isConnected} />
            <MenuListComposition
              handleConnectModalOpen={handleConnectWalletModalOpen}
              handlePasswordModalOpen={handlePasswordModalOpen}
              handleLogout={handleLogout}
              handleFeeModalOpen={handleFeeModalOpen}
            />
          </ButtonBlock>
        </HeaderContainer>
        <StyledContainer>
          {(passwordModal && (
            <ResetPasswordModal
              handlePasswordChange={handlePasswordChange}
              passwordModal={passwordModal}
              handlePasswordModalClose={handlePasswordModalClose}
            />
          )) ||
            (feeModal && (
              <ResetFeeModal
                feeAmount={feeAmount}
                handleFeeChange={handleFeeChange}
                feeModal={feeModal}
                handleFeeModalClose={handleFeeModalClose}
              />
            )) || (
              <ConnectWalletModal
                handleFeeChange={handleConnectWalletChange}
                feeModal={walletModal}
                handleFeeModalClose={handleWalletModalClose}
              />
            )}
          {children}
        </StyledContainer>
      </StyledWrapper>
      {
        <Notification
          isOpen={notificationIsOpen}
          onClose={hadleCloseNotification}
          isFeeChanged={isFeeChanged}
        />
      }
    </MainContainer>
  );
};

export default Container;
