import * as React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ArrowButtonIcon from '../../svg/ArrowButtonIcon';
import { StyledMenuArrow } from '../Container/styles';
import { StyledControlTitle, MenuBox } from './styles';
import { useState } from 'react';
import { useAccount } from 'wagmi';

type ControlProps = {
  handlePasswordModalOpen: () => void;
  handleLogout: () => void;
  handleFeeModalOpen: () => void;
  handleConnectModalOpen: () => void;
};
export default function MenuListComposition(props: ControlProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { address, isConnected } = useAccount();

  const [walletConnected, setwalletConnected] = useState(isConnected);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <MenuBox direction="row">
      <div>
        <div onClick={handleToggle}>
          <StyledControlTitle
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
          >
            Admin
          </StyledControlTitle>
          <StyledMenuArrow>{ArrowButtonIcon()}</StyledMenuArrow>
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          onReset={undefined}
          onResetCapture={undefined}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transform: 'translate3d(25px, -6px, 0px)',
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {/* <MenuItem onClick={props.handlePasswordModalOpen}>
                      Change Password
                    </MenuItem> */}
                    {!walletConnected ? (
                      <MenuItem onClick={props.handleConnectModalOpen}>
                        Connect Wallet
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={props.handleFeeModalOpen}>
                        Change Platform Fee
                      </MenuItem>
                    )}
                    <MenuItem onClick={props.handleLogout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </MenuBox>
  );
}
