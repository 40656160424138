import { DockSharp } from '@mui/icons-material';
import { createSelector } from 'reselect';
import { RootState } from '..';

const nftCollectionsState = (state: RootState) => state.nftCollections;

/**
 * @description Get list of nft collections available on current page (pagination is used)
 */
export const nftCollectionsSelector = createSelector(
  nftCollectionsState,
  ({ nftCollections }) => nftCollections
);

/**
 * @description Get data of selected nft collection
 */
export const selectednftCollectionSelector = createSelector(
  nftCollectionsState,
  ({ selectednftCollection }) => selectednftCollection
);
export const modalnftCollectionSelector = createSelector(
  nftCollectionsState,
  ({ modalCollections }) => modalCollections
);
export const formCleanerSelector = createSelector(
  nftCollectionsState,
  ({ formCleaner }) => formCleaner
);
export const nftCollectionStatusSelector = createSelector(
  nftCollectionsState,
  ({ statuses }) => statuses
);
