import { type } from 'os';

export enum ICON_TITLE {
  EDIT_POST = 'Edit post',
  ADD_POST = 'Add post',
  CHANGE_POST_STATUS = 'Change status',
  SHOW_DETAILS = 'Show details',
}

export enum BTN_TITLE {
  SAVE = 'Save',
  CANCEL = 'Cancel',
  CREATE = 'Create new',
  EDIT = 'Edit',
  SHOW = 'Show',
  DELETE = 'Delete',
}

export enum INPUT_LABEL {
  TITLE = 'Title',
  STATUS = 'Status',
  SHORT_DESCRIPTION = 'Short Description',
  TOPIC = 'Topic',
  IMAGE = 'Image',
  TAGS = 'Tags',
  IMAGE_ALT = 'Image Alt',
  SEO_TITLE = 'Seo Title',
  SEO_KEYWORD = 'Seo Keyword',
  SEO_DESCRIPTION = 'Seo Description',
  URL = 'Url',
  ADDTAG = 'Add tag',
  SIMILAR_ARTICLES = 'Similar Articles',
}

export enum TOPIC_ACTIONS {
  EDIT = 'edit topic',
  ADD = 'create new topic',
  URL_EDIT = 'edit',
  URL_ADD = 'add',
  TOPICS = 'Topics',
  USERS = 'Users',
  NFT_COLLECTIONS = 'NFT Collections',
  NFT = 'NFT',
  PROJECT = 'Projects',
  TOKENS = 'TOKENS',
}
export type ObjectType = {
  [key: number]: {
    url: string;
    market: `0x${string}`;
    contract: `0x${string}`;
  };
};
export const NFT_MARKETPLACE: ObjectType = {
  5: {
    url: process.env.API_URL ?? 'https://eth-goerli.public.blastapi.io', // Fallback public
    contract: '0x513931Ea3572e84f0805dd14E98AbF05c8882dbA', // ERC1155
    market: '0xae0396635fD8BC7b2e39B811B13931F6c5424b64', // Marketplace
  },
  97: {
    url: 'https://data-seed-prebsc-1-s1.binance.org:8545', // Public node
    contract: '0xf25B11e8b29e6150EaDb428FF0d035ea9beACe03',
    market: '0x720aF7bdF2A979CD2FB610cfCbE9AdE386AEE6f8',
  },
};
export enum TOPICS_BUTTON {
  USERS = '/users',
  COLLECTIONS = '/nft-collections',
  TOKENS = '/tokens',
  PROJECTS = '/projects',
  NFTS = '/nfts',
}
export enum STATUS_SELECT {
  PENDING = 'PENDING',
  UPCOMING = 'UPCOMING',
  APPROVED = 'APPROVED',
}
export enum STATUS_TYPES_ENUM {
  PENDING = 'pending',
  UPCOMING = 'upcoming',
  APPROVED = 'approved',
}

export enum LANGUAGES_TYPES_ENUM {
  ENGLISH = 'en',
  UKRAINIAN = 'uk',
}

export enum LANGUAGES_FILTER_ENUM {
  All = 'All Languages',
  ENGLISH = 'English',
  UKRAINIAN = 'Ukrainian',
}

export const BASE_URL = process.env.REACT_APP_URL;

export enum LoadingResultsT {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum Messages {
  errorPassword = 'Sorry, failed to change password',
  successPassword = 'Password changed successfully',
  errorEditPost = 'You cannot change status to Published. Fill please all fields first',
  successEditPost = 'Post changed successfully',
  successCreatePost = 'Post created successfully',
  needTime = 'Thank you! The fee amount has been sent to your contract for processing. Please wait for the result for several minutes',
}

export enum UrlStyles {
  ITEM_HEIGHT = 48,
  ITEM_PADDING_TOP = 8,
}

export enum RoleUsers {
  ADMIN = 'Administrator',
  USER = 'User',
  MODERATOR = 'Moderator',
}
export enum LongMenuConfig {
  EDIT = 'Edit',
  SHOW_DETAILS = 'Show details',
  DEACTIVATE = 'Deactivate',
}
