import { Dialog, Button } from '@mui/material';
import { createTheme, styled } from '@mui/system';
import {
  Color,
  BorderRadius,
  BoxShadow,
  Border,
  FontFamily,
  TextSize,
} from '../../helpers/themeStyles';

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '45px 16px 28px',
});
export const StyledDialoig = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: Color.BACKGROUND,
    boxShadow: BoxShadow.ACCENT_BOX_SHADOW,
    borderRadius: BorderRadius.SMALL,
    border: Border.TRANSPARENT_BORDER,
    backgroundImage: `linear-gradient(${Color.BACKGROUND}, ${Color.BACKGROUND}), ${Color.BUTTON_GRADIENT_PINK_TO_PURPLE}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    width: '412px!important',
  },
});

export const StyledTitle = styled('h3')({
  margin: 0,
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.MIDDLE,
  lineHeight: '150%',
  color: Color.WHITE,
  marginBottom: '12px',
});

export const StyledDexIcon = styled('img')({
  marginBottom: '22px',
});

export const StyledPrivacyPolicy = styled('p')({
  margin: 0,
  textAlign: 'center',
  fontFamily: FontFamily.INTER,
  fontWeight: 600,
  fontSize: TextSize.EXTRA_SMALL,
  lineHeight: '150%',
  color: Color.TEXT_GRAY_SECONDARY,
  marginBottom: '32px',
});

export const StyledSignBtn = styled(Button)({
  width: '100%',
  background: Color.BUTTON_GRADIENT_ORANGE_TO_PINK,
  padding: '16px 0px',
  fontFamily: FontFamily.ZENDOTS,
  fontWeight: 400,
  fontSize: TextSize.SMALL,
  lineHeight: '150%',
  color: Color.WHITE,
  textTransform: 'uppercase',
  marginBottom: '28px',
});

export const StyledCancelBtn = styled(Button)({
  fontFamily: FontFamily.INTER,
  fontWeight: 700,
  fontSize: TextSize.SMALL,
  lineHeight: '160%',
  color: Color.PURPLE,
  textTransform: 'none',
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  width: '100%',
});
