import { RootState } from '..';
import { createSelector } from '@reduxjs/toolkit';

const networkState: any = (state: RootState) => state.network;

export const networkSelector = createSelector(
  networkState,
  ({ network }) => network
);

export const selectedNetworkSelector = createSelector(
  networkState,
  ({ selectedNetwork }) => selectedNetwork
);

export const errorNetworkSelector = createSelector(
  networkState,
  ({ error }) => error
);
