import { createSlice } from '@reduxjs/toolkit';
import { IStoreStatuses, STATUS_ENUM } from '../../types/statuses';
import { StatusGenerator } from '../../utils/StatusGenerator';
import {
  getAllTokensThunk,
  getTokenByIdThunk,
  updateTokenByIdThunk,
} from './thunks';
import { IToken } from '../../types/token';
import { IPaginate } from '../../types/pagination';
import { deleteNftCollectionByIdThunk } from '../nftCollections/thunks';

interface IState {
  tokens: IPaginate | null;
  selectedToken: IToken | null;
  statuses: IStoreStatuses;
  modalTokens: boolean;
  formCleaner: boolean;
}

const initialState: IState = {
  tokens: null,
  selectedToken: null,
  statuses: StatusGenerator.generateStatuses([
    getAllTokensThunk.typePrefix,
    getTokenByIdThunk.typePrefix,
    updateTokenByIdThunk.typePrefix,
  ]),
  modalTokens: false,
  formCleaner: false,
};

const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setSelectedToken: (state, action) => {
      state.selectedToken = action.payload.data ?? null;
    },
    setTokens: (state, action) => {
      state.tokens = action.payload.data ?? null;
    },
    modalTokens: (state, action) => {
      state.modalTokens = action.payload ?? false;
    },
    formCleaner: (state, action) => {
      state.formCleaner = action.payload ?? false;
    },
    resetStatus: (state) => {
      state.statuses = initialState.statuses;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllTokensThunk.pending, (state) => {
        state.statuses[getAllTokensThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getAllTokensThunk.rejected, (state, { error }) => {
        state.statuses[getAllTokensThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getAllTokensThunk.fulfilled, (state, { payload }) => {
        state.statuses[getAllTokensThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.tokens = payload;
      })
      .addCase(getTokenByIdThunk.pending, (state) => {
        state.statuses[getTokenByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getTokenByIdThunk.rejected, (state, { error }) => {
        state.statuses[getTokenByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getTokenByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getTokenByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedToken = payload;
      })
      .addCase(updateTokenByIdThunk.pending, (state) => {
        state.statuses[updateTokenByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(updateTokenByIdThunk.rejected, (state, { error }) => {
        state.statuses[updateTokenByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(updateTokenByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[updateTokenByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedToken = payload;
      })
      .addCase(deleteNftCollectionByIdThunk.pending, (state) => {
        state.statuses[deleteNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(deleteNftCollectionByIdThunk.rejected, (state, { error }) => {
        state.statuses[deleteNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(deleteNftCollectionByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[deleteNftCollectionByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      }),
});

export default tokensSlice;
