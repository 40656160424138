import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true;
  }
}

export const Color = {
  BACKGROUND: '#161616',
  BACKGROUND_MAIN: '#251A2A',
  BACKGROUND_MAIN_WITH_OPACITY: `rgba(37, 26, 42, 0.6)`,
  BACKGROUND_TAP_BAR: 'rgba(61, 61, 89, 0.7)',
  LINKEDIN_COLOR: '#5DC6D4',
  BACKGROUND_NFTS:
    'linear-gradient(180deg, rgba(22, 22, 22, 0.3) 49.73%, rgba(248, 0, 163, 0.138) 100%)',
  BACKGROUND_OVERVIEW_NFT: `radial-gradient(59.72% 59.72% at 50% 50%, #311D33 39.37%, #151415 100%)`,
  BUTTON_GRADIENT_PINK_TO_PURPLE:
    'linear-gradient(90deg, #E15096 0%, #AE2CB1 53.12%, #4132CF 100%)',
  BUTTON_GRADIENT_ORANGE_TO_PINK:
    'linear-gradient(90deg, #F89D2F 0%, #E63E36 53.12%, #C81FAD 100%)',
  BUTTON_EVEN_TAP_BAR_GRADIENT:
    'linear-gradient(90deg, #FFAB45 0%, #FF5B53 53.12%, #F02CD1 100%)',
  BUTTON_ODD_TAP_BAR_GRADIENT:
    'linear-gradient(232.43deg, #552DC7 -5.33%, #32D6AF 111.63%)',
  BUTTON_HOME_SIDE_MENU_GRADIENT:
    'linear-gradient(232.43deg, #32D6AF -5.33%, #552DC7 111.63%)',
  SWAP_GRADIENT:
    'linear-gradient(180deg, rgba(44, 31, 73, 0.6) 0%, rgba(94, 39, 120, 0.6) 100%)',
  SWAP_GRADIENT_WITHOUT_OPACITY:
    'linear-gradient(180deg, #251C36 0%, #412051 100%)',
  TEXT_GRADIENT:
    'linear-gradient(90deg, #FFAB45 0%, #FF5B53 53.12%, #F02CD1 100%);',

  AMOUNT_BACKGROUND: 'rgba(22, 22, 22, 0.6)',
  BORDER_COLOR: '#CF239F',
  BORDER_WHITE_EXTRA_OPACITY: 'rgba(255, 255, 255, 0.1)',
  SWAP_BORDER_COLOR: 'rgba(83, 50, 214, 0.22)',
  BORDER_HOT_COLLECTION_NFT: `linear-gradient(0deg, rgba(225, 80, 150, 0.6) 0%, rgba(174, 44, 177, 0.4) 100%)`,

  WHITE: '#ffffff',
  WHITE_OPACITY: 'rgba(255, 255, 255, 0.2)',
  WHITE_OPACITY_LIGHT: 'rgba(255, 255, 255, 0.6)',
  PURPLE: '#B032D6',
  PURPLE_OPACITY: 'rgba(255, 115, 160, 0.1)',
  DARK_PURPLE: '#3C214D',
  DARK_PURPLE_BACKGROUND: '#2D1D3E',
  LIGHT_PURPLE_BACKGROUND: '#361E49',
  DARK_PURPLE_OPACITY: 'rgba(176, 50, 214, 0.1)',
  DARK_PURPLE_OPACITY_NEXT: 'rgba(176, 50, 214, 0.15)',
  DARK_PURPLE_OPACITY_MIDDLE: 'rgba(176, 50, 214, 0.2)',
  DARK_PURPLE_OPACITY_LIGHT: 'rgba(176, 50, 214, 0.4)',
  DARK_PURPLE_OPACITY_LARGE: 'rgba(22, 22, 22, 0.6)',
  BLACK_OPACITY: 'rgba(0, 0, 0, 0.2)',
  BLACK_OPACITY_LARGE: 'rgba(0, 0, 0, 0.7)',
  GREEN: '#11C243',
  RED: 'rgb(250, 43, 57)',
  LIGHT_RED: '#FF3156',
  PINK: '#FF73A0',
  YELLOW: 'rgb(243, 132, 30)',
  WARNING_NFT: 'rgba(255, 49, 86, 0.1)',

  TEXT_PRIMARY: '#289AF4',
  TEXT_PRIMARY_OPACITY: 'rgba(40, 154, 244, 0.5)',
  TEXT_GRAY_PRIMARY: '#333333',
  TEXT_GRAY_SECONDARY: '#CECECE',
  TEXT_SECONDARY: '#83869E',
  TEXT_SECONDARY_LIGHT: '#BDBFCC',
  TEXT_GREY: '#616161',
  TEXT_OPACITY_LIGHT: 'rgba(255, 255, 255, 0.8)',
};

export const TextSize = {
  LITTLE: '10px',
  SUPER_SMALL: '12px',
  TABLE_SMALL: '13px',
  EXTRA_SMALL: '14px',
  SMALL: '15px',
  NORMAL: '16px',
  MIDDLE: '18px',
  MIDDLE_PLUS: '20px',
  MEDIUM: '24px',
  LARGE: '32px',
  EXTRA_LARGE: '36px',
  HUGE: '40px',
};

export const BorderRadius = {
  EXTRA_SMALL: '4px',
  SEMI_SMALL: '6px',
  SMALL: '8px',
  SEMI_NORMAL: '12px',
  NORMAL: '16px',
  MIDDLE: '18px',
  MEDIUM: '20px',
  SEMI_MEDIUM: '30px',
  LARGE: '32px',
  HUGE: '100px',
};

export const FontFamily = {
  INTER: 'Inter',
  ZENDOTS: 'Zen Dots',
};

export const Border = {
  ACCENT_BORDER: '1px solid rgba(176, 50, 214, 0.4)',
  TRANSPARENT_BORDER: '1px solid transparent',
};

export const BoxShadow = {
  ACCENT_BOX_SHADOW:
    '0px 0px 40px rgba(207, 35, 159, 0.21), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 12px rgba(0, 0, 0, 0.86)',
  BUTTON_SHADOW: '0px 2px 8px #6921DE',
  NFT_CARD_SHADOW:
    '0px 4px 12px rgba(147, 15, 160, 0.2), 0px 4px 15px rgba(86, 14, 94, 0.79)',
};

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 429,
      xs: 448,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
