import React from 'react';

const TelegramIcon = () => {
  return (
    <svg
      width='21'
      height='19'
      viewBox='0 0 21 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3027 0.984549C18.0138 0.997119 17.7486 1.08023 17.5156 1.17205C17.3004 1.25681 16.5275 1.58165 15.2969 2.09783C14.0662 2.61401 12.4355 3.29841 10.6973 4.02947C7.22084 5.4916 3.31424 7.1363 1.3125 7.97869C1.24376 8.00733 0.964585 8.09279 0.65625 8.3283C0.3472 8.56435 0.00390625 9.07664 0.00390625 9.63689C0.00390625 10.0888 0.22952 10.5491 0.501953 10.8127C0.774386 11.0762 1.05049 11.1994 1.28125 11.2912V11.2892C2.06128 11.6 4.39069 12.5321 4.94531 12.7541C5.14204 13.3436 5.98659 15.8675 6.1875 16.5021H6.18555C6.32756 16.9513 6.46598 17.2441 6.6582 17.4885C6.75431 17.6106 6.86904 17.7214 7.00977 17.8088C7.06376 17.8423 7.12355 17.8683 7.18359 17.8928C7.19196 17.8963 7.20059 17.8972 7.20898 17.9006L7.18555 17.8947C7.20296 17.9017 7.21858 17.912 7.23633 17.9181C7.26524 17.9281 7.28529 17.9278 7.32422 17.9357C7.46122 17.9779 7.59798 18.006 7.72461 18.006C8.26822 18.006 8.60156 17.7111 8.60156 17.7111L8.62305 17.6955L10.9707 15.7092L13.8457 18.3693C13.8982 18.4435 14.3096 19.0002 15.2617 19.0002C15.8298 19.0002 16.279 18.719 16.5664 18.424C16.8538 18.129 17.0328 17.8272 17.1133 17.4181L17.1152 17.4162C17.1794 17.086 19.9316 3.2658 19.9316 3.2658L19.9258 3.28924C20.0114 2.90689 20.0367 2.53714 19.9355 2.16033C19.8344 1.78352 19.5613 1.4158 19.2324 1.22283C18.9036 1.02986 18.5917 0.971979 18.3027 0.984549ZM17.9082 3.174C17.7994 3.72003 15.334 16.1061 15.1816 16.883L11.0293 13.0412L8.22266 15.4142L9 12.3752C9 12.3752 14.3625 6.94703 14.6855 6.63103C14.9455 6.37803 15 6.28935 15 6.20135C15 6.08435 14.9398 6.00017 14.8008 6.00017C14.6758 6.00017 14.506 6.11996 14.416 6.17596C13.2724 6.88896 8.40128 9.66486 6.00586 11.0275C5.8617 10.9697 3.6974 10.1006 2.53125 9.63494C4.60551 8.76194 8.16116 7.26598 11.4727 5.87322C13.2106 5.14228 14.8408 4.45727 16.0703 3.94158C17.1082 3.50627 17.6495 3.28088 17.9082 3.174ZM15.1523 17.0256H15.1543C15.1543 17.0258 15.1524 17.0311 15.1523 17.0314C15.1536 17.025 15.1514 17.0306 15.1523 17.0256Z'
        fill='white'
      />
    </svg>
  );
};

export default TelegramIcon;
