import * as React from 'react';
import { useEffect } from 'react';
import { Box, Grid, Tooltip, Pagination, Checkbox } from '@mui/material';
import { format } from 'date-fns';

import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { deleteNftByIdThunk, getAllNftsThunk } from '../../redux/nft/thunks';
import { nftsSelector } from '../../redux/nft/selectors';
import { BTN_TITLE, ICON_TITLE } from '../../helpers/constants';
import {
  LinkWrapper,
  StyledStatusButton,
  StyledTable,
  StyledTBody,
  StyledTRow,
  StyledTitleNoTopics,
  NoTopicsWrapper,
  ImageBackground,
  NoTopicsTitleWrapper,
  StyledTDataUrl,
  StyledTDataName,
  StyledHead,
  StyledNameBox,
  StyledStatusBox,
  StyledUrlBox,
  StyledDataBox,
  StyledActionBox,
  StyledTDataStatus,
  StyledTDataDate,
  StyleName,
  StyledUrl,
  StyledLine,
  ActionWrapper,
  GridWrapper,
  BoxWrapper,
  StyledGridSelect,
  TopicHeader,
  StyledTDataAction,
  StyledImage,
} from './styles';
import ActionTopicButton from '../../components/ActionTopicButton';
import Container from '../../components/Container';
import { INft } from '../../types/nft';
import { formatWalletKey } from '../../helpers/formatWalletKey';

import {
  AvatarWrap,
  CardWrap,
  FotoNftCollection,
  RowWrap,
  SmallText,
  StatusNftCollection,
  TableItemOwnerStyled,
  TableItemStyled,
  TableItemWrap,
  TableTitleOwnerStyled,
  TableTitleOwnerWrap,
  TableTitleStyled,
  TableTitleWrap,
  TitleText,
} from '../NftCollections/styles';
import ModalDetails from '../../components/ModalDetails/ModalDetails';
import LongMenu from '../../components/LongMenu/LongMenu';
import { INftCollections } from '../../types/nftCollections';
import { formatIpfsUrl } from '../../utils/formatIpfsUrl';

const Nfts: React.FC = () => {
  const dispatch = useAppDispatch();
  const nfts = useAppSelector(nftsSelector);
  const [isModal, setModalDetails] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(nfts?.page);
  const [selectedNFTId, setSelectedNFTId] = React.useState<string>('');
  useEffect(() => {
    dispatch(getAllNftsThunk({ limit: 8, page: page }));
  }, [dispatch, selectedNFTId]);

  const handleModalClose = () => {
    setModalDetails(false);
  };
  const renderOwnerItem = (ownerObject: any) => {
    for (const property in ownerObject) {
      return (
        <div style={{ display: 'flex' }}>
          <TableItemOwnerStyled>
            <TitleText>{formatWalletKey(property)}</TitleText>
          </TableItemOwnerStyled>
          <TableItemOwnerStyled>
            <TitleText>{ownerObject[property]}</TitleText>
          </TableItemOwnerStyled>
        </div>
      );
    }
  };

  const renderOwner = (ownerObject: any) => {
    return (
      <>
        <thead>
          <TableTitleOwnerWrap>
            <TableTitleOwnerStyled>{'owner address'}</TableTitleOwnerStyled>
            <TableTitleOwnerStyled>{'NFT number'}</TableTitleOwnerStyled>
          </TableTitleOwnerWrap>
        </thead>
        <tbody>
          {/* {renderArr(ownerObject)} */}
          <div style={{ display: 'flex' }}>
            <TableItemOwnerStyled>
              {Object.keys(ownerObject).map((item) => {
                return (
                  <TitleText style={{ marginBottom: '10px' }}>
                    {ownerObject[item] === 0 ? '' : formatWalletKey(item)}
                  </TitleText>
                );
              })}
            </TableItemOwnerStyled>
            <TableItemOwnerStyled>
              {Object.values(ownerObject).map((item) => {
                return (
                  <TitleText style={{ marginBottom: '10px' }}>
                    {item === 0 ? '' : Number(item)}
                  </TitleText>
                );
              })}
            </TableItemOwnerStyled>
          </div>
        </tbody>
      </>
    );
  };

  const handleModalOpen = () => {
    setModalDetails(true);
  };
  const handleDelete = async (id: string) => {
    await dispatch(deleteNftByIdThunk(id));
    setSelectedNFTId(id);
  };
  const renderNft = (arr: any) => {
    return (
      <CardWrap style={{ borderCollapse: 'collapse' }}>
        <thead>
          <TableTitleWrap>
            <TableTitleStyled>{'NFT Name'}</TableTitleStyled>
            <TableTitleStyled>{'NFT owner'}</TableTitleStyled>
            <TableTitleStyled>{'NFT collection'}</TableTitleStyled>
            <TableTitleStyled style={{ textAlign: 'center' }}>
              {'Creation date'}
            </TableTitleStyled>
            {/* <TableTitleStyled>{'show details'}</TableTitleStyled> */}
          </TableTitleWrap>
        </thead>
        <tbody>
          {arr.map((nft: INft) => {
            return (
              <TableItemWrap key={nft._id}>
                <TableItemStyled>
                  <RowWrap>
                    <AvatarWrap>
                      <StyledImage
                        src={formatIpfsUrl(nft.image[0])}
                        alt="nft image"
                      />
                    </AvatarWrap>
                    <div>
                      <TitleText>{nft.name}</TitleText>
                      <SmallText
                        style={{ marginTop: '6px', fontWeight: '700' }}
                      >
                        {nft._id}
                      </SmallText>
                    </div>
                  </RowWrap>
                </TableItemStyled>
                <TableItemStyled>
                  {renderOwner(nft.owner)}
                  <TitleText>{nft.owner._id}</TitleText>
                </TableItemStyled>
                <TableItemStyled>
                  <TitleText>
                    {nft.nft_collection
                      ? nft.nft_collection.name
                      : 'no name collection'}
                  </TitleText>
                </TableItemStyled>
                <TableItemStyled style={{ textAlign: 'center' }}>
                  <StyledTDataDate>
                    {format(new Date(`${nft.createdAt}`), 'dd.MM.yyyy')}
                  </StyledTDataDate>
                </TableItemStyled>
                <TableItemStyled>
                  <StyledTDataAction>
                    <LinkWrapper to={``}>
                      <Tooltip
                        title={ICON_TITLE.SHOW_DETAILS}
                        arrow
                      >
                        <ActionTopicButton
                          onClick={() => handleDelete(nft._id)}
                          text={BTN_TITLE.DELETE}
                        />
                      </Tooltip>
                    </LinkWrapper>
                  </StyledTDataAction>
                </TableItemStyled>
              </TableItemWrap>
            );
          })}
        </tbody>
      </CardWrap>
    );
    // return arr?.map((nft: INft) => (
    //   <>
    //     <StyledTRow key={nft._id}>
    //       <StyledTDataName>
    //         <StyleName>{nft.name}</StyleName>
    //       </StyledTDataName>
    //       <StyledTDataStatus>
    //         <StyledStatusButton>{nft.last_sale}</StyledStatusButton>
    //       </StyledTDataStatus>
    //       <StyledTDataUrl>
    //         <FotoNftCollection
    //           src={nft.image}
    //           alt="collection image"
    //         />
    //         {/* <StyledUrl>{nft.image}</StyledUrl> */}
    //       </StyledTDataUrl>
    //       <StyledTDataDate>
    //         {format(new Date(`${nft.createdAt}`), 'dd.MM.yyyy')}
    //       </StyledTDataDate>
    //       <StyledTDataAction>
    //         <LinkWrapper to={``}>
    //           <Tooltip
    //             title={ICON_TITLE.SHOW_DETAILS}
    //             arrow
    //           >
    //             <ActionTopicButton
    //               onClick={() => handleModalOpen()}
    //               text={BTN_TITLE.SHOW}
    //             />
    //           </Tooltip>
    //         </LinkWrapper>
    //       </StyledTDataAction>
    //     </StyledTRow>
    //     <StyledLine></StyledLine>
    //     {isModal && (
    //       <ModalDetails
    //         handleChange={handleModalOpen}
    //         modalDetails={isModal}
    //         handleModalClose={handleModalClose}
    //         data={nft}
    //       />
    //     )}
    //   </>
    // ));
  };

  return (
    <Container>
      {nfts?.docs.length ? (
        <BoxWrapper>
          <StyledGridSelect
            item
            xs={2}
          >
            <TopicHeader>
              <GridWrapper
                container
                justifyContent="space-between"
              >
                <Grid item></Grid>
              </GridWrapper>
            </TopicHeader>
          </StyledGridSelect>
          <Box>
            <Grid
              container
              xs={12}
            >
              {/* <StyledHead>
                <StyledNameBox>Name</StyledNameBox>
                <StyledStatusBox>Last Sale</StyledStatusBox>
                <StyledUrlBox>Image</StyledUrlBox>
                <StyledDataBox>Creation Date</StyledDataBox>
                <StyledActionBox>
                  <ActionWrapper>Action</ActionWrapper>
                </StyledActionBox>
              </StyledHead> */}
              <StyledTable>
                <StyledTBody>{renderNft(nfts?.docs)}</StyledTBody>
              </StyledTable>
            </Grid>
          </Box>
          <Pagination
            hideNextButton
            hidePrevButton
            defaultPage={nfts?.page}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              'li button': {
                fontFamily: `"Montserrat", sans-serif`,
                fontWeight: 600,
                color: '#717384',
              },
            }}
            page={nfts?.page}
            count={nfts?.totalPages}
            onChange={(event: React.ChangeEvent<unknown>, page: number) => {
              dispatch(
                getAllNftsThunk({
                  limit: nfts?.limit,
                  page: page,
                })
              );
            }}
          />
        </BoxWrapper>
      ) : (
        <NoTopicsWrapper>
          <ImageBackground></ImageBackground>
          <NoTopicsTitleWrapper>
            <StyledTitleNoTopics>
              Have no information about NFT
            </StyledTitleNoTopics>
          </NoTopicsTitleWrapper>
        </NoTopicsWrapper>
      )}
    </Container>
  );
};

export default Nfts;
