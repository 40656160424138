import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config/api';
import { API_ROUTES } from '../../config/apiRoutes';
import { IAdminChangePassword } from '../../types/auth';
import { IPaginate } from '../../types/pagination';
import {
  ITokensListQueryData,
  IToken,
  GetTokensQuery,
} from '../../types/token';

/**
 * @description Dispatch this thunk to get list of tokens
 * @param {ITokensListQueryData} queryData - Object containing limit and page (used for pagination)
 */
export const getAllTokensThunk = createAsyncThunk<
  IPaginate<IToken>,
  GetTokensQuery
>(
  'tokens/getAllTokensThunk',
  async (queryData: ITokensListQueryData | undefined, { rejectWithValue }) => {
    const { limit = 20, page = 1, name } = queryData ?? {};
    try {
      const { data } = await API.get(
        API_ROUTES.TOKENS.GET_LIST(limit, page, name)
      );
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
/**
 * @description Dispatch this thunk to get token by id
 * @param {string} id - String id to find current token
 */
export const getTokenByIdThunk = createAsyncThunk(
  'tokens/getTokenByIdThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.TOKENS.GET_BY_ADDRESS(id));
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

/**
 * @description Dispatch this thunk to update token by id
 * @param {string} id - String id to find current token
 */
export const updateTokenByIdThunk = createAsyncThunk(
  'tokens/updateTokenByIdThunk',
  async (values: IToken, { rejectWithValue }) => {
    try {
      const { data } = await API.put(API_ROUTES.TOKENS.UPDATE(values._id), {
        ...values,
      });
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
